import axios from 'axios';
import store from '@/modules/adaptedStorage';
import router from '@/router';
import { alertController, loadingController } from '@ionic/vue';

interface CyanRequestArgs {
	params?: object;
	isPost?: boolean;
	silent?: boolean;
	timeout?: number;
	files?: object;
	needsAuth?: boolean;
	needsPreflight?: boolean;
	didPreflight?: boolean;
	retries?: number;
	isModal?: boolean | string;
	_loading?: HTMLIonLoadingElement;
	_bail?: boolean;
}

async function cyanRequest(endpoint: string, o: CyanRequestArgs): Promise<Record<string, unknown>> {

	// Si hay autenticación, obtener los datos del usuario de storage

	interface HeadersInterface {
		Authorization?: string;
		'X-CSRF-TOKEN'?: string;
		'X-Cyan-API-Serial'?: string;
		'Content-Type'?: string;
	}

	interface AxiosParamsInterface {
		method: "get" | "post";
		url: string;
		baseURL: string;
		headers?: HeadersInterface;
		params?: object;
		data?: object | FormData;
		timeout?: number;
		withCredentials?: boolean;
	}

	const headers: HeadersInterface = {};

	let serverRoot = 'https://apiv1.nuevasideas.com/';
	if (process.env && process.env.VUE_APP_API_SERVER_ROOT)
		serverRoot = process.env.VUE_APP_API_SERVER_ROOT;

	if (serverRoot.substr(-1) !== '/')
		serverRoot += '/';

	let apiRoot = serverRoot + 'api/';

	if (process.env && process.env.VUE_APP_API_ROOT)
		apiRoot = process.env.VUE_APP_API_ROOT;

	if (apiRoot.substr(-1) !== '/')
		apiRoot += '/';


	let apiSerial = '4';

	if (process.env && process.env.VUE_APP_API_SERIAL)
		apiSerial = process.env.VUE_APP_API_SERIAL;

	headers['X-Cyan-API-Serial'] = apiSerial;

	if (o.needsPreflight) {

		return axios.request({
			method: 'get',
			baseURL: serverRoot,
			url: 'sanctum/csrf-cookie',
			withCredentials: true
		}).then(function (d) {
			if (d.headers && d.headers['X-CSRF-TOKEN'])
				store.commit('setUserCsrf', d.headers['X-CSRF-TOKEN']);
		}).catch(function () {
			return true;
		}).then(function () {
			return cyanRequest(endpoint, { ...o, needsPreflight: false, didPreflight: true });
		})
	}

	if (o.needsAuth) {
		if (!store.state.isLoggedIn) {
			router.replace('/login');
			return Promise.resolve({ ok: false, reason: 'Esta página necesita estar autenticado.' });
		}

		const userData = store.state.userData;

		// Opción 1 - bearer token

		if (userData.token) {
			headers.Authorization = 'Bearer ' + userData.token;
		}

		// Opción 2 - CSRF token

		const csrf = store.getters.userCsrf;

		if (userData.csrf) {
			headers["X-CSRF-TOKEN"] = csrf;
		}
	}

	// Mostrar modal?

	let loading: HTMLIonLoadingElement | undefined;

	if (o._loading) {
		loading = o._loading;
	} else if (o.isModal) {
		const modalMessage = (o.isModal === true) ? 'Cargando...' : o.isModal;

		loading = await loadingController.create({ message: modalMessage });
		await loading.present();

	}

	// Hacer petición

	const axiosParams: AxiosParamsInterface = {
		method: o.isPost ? 'post' : 'get',
		url: endpoint,
		timeout: (typeof o.timeout === 'undefined') ? 10000 : o.timeout,
		baseURL: apiRoot,
		withCredentials: true
	};


	if (o.isPost) {
		if (o.files) {
			headers['Content-Type'] = 'multipart/form-data';
			const d = new FormData();
			axiosParams.data = d;

			if (o.params) for (const key in o.params) {
				d.append(key, (o.params as any)[key]);
			}

			for (const key in o.files) {
				const f = (o.files as any)[key];
				d.append(key, f, f.name);
			}


		} else {
			axiosParams.data = o.params;
		}
	} else {
		axiosParams.params = o.params;
	}

	if (headers) axiosParams.headers = headers;


	const axiosPromise = axios.request(axiosParams)
		.then(function (r) {
			const d = r.data instanceof Object ? r.data : {
				ok: false,
			};

			if (!d.ok && !d.reason)
				d.reason = 'Error interno accediendo a los datos, favor de reintentar';


			if (o.needsAuth && !d.ok && !d.hasAuth) {
				d.nextRoute = '/login';
				store.commit('userLogout');
				d.reason = 'Debe volver a introducir su contraseña.';
			}


			// Actualizar el token CSRF si viene alguno

			if (r.headers && r.headers['X-CSRF-TOKEN']) {
				store.commit('setUserCsrf', r.headers['X-CSRF-TOKEN']);
			}
			return d;

		}).catch(function (err) {
			console.error(err);
			console.error(err.code);
			console.error(JSON.stringify(err.response));

			const retries = (typeof o.retries === 'undefined') ? (o.isPost ? 0 : 2)
				: o.retries;

			if (/*err.code === 'ECONNABORTED' &&*/ retries) {

				return new Promise((resolve) => {
					setTimeout(function () { resolve(true); }, 3000);
				}).then(function () {
					return cyanRequest(endpoint, { ...o, _bail: true, retries: retries - 1, _loading: loading });
				});

			}

			return {
				ok: false,
				reason: (err.code === 'ECONNABORTED') ? 'No se pudo contactar con el servidor.' :
					'No tiene conexión o hubo un error accediendo a los datos, favor de reintentar',
				err: err
			}
		}).then(async function (d: any) {

			if (o._bail) return d;
			if (loading) {
				if (d.ok) {
					new Promise((resolve) => {
						setTimeout(function () { resolve(true); }, 300);
					}).then(function () { (loading as any).dismiss() });
				} else {
					loading.dismiss();
				}
			}

			// Aquí tenemos seguro ok y reason

			if (!d.ok) {
				if (!o.silent || d.nextRoute || d.needsUpdate) {
					// Alert con el error

					const alert = await alertController.create({
						header: d.header || "Error",
						message: d.reason,
						buttons: [ {
							text: 'OK',
							handler: function() { 
								if (d.needsUpdate) {
									location.reload();
								}
							}
						}
						]
					});
					alert.present();

				}
				// Redirección

				if (d.nextRoute) {
					router.replace(d.nextRoute);
				}
			}
			return d;
		});

	return axiosPromise;

}

// export { CyanRequestArgs };

export default cyanRequest;
