interface ElementoMapaCyan {
	codigo: string;
	nombre: string;
	nombrePadre?: string;
}

interface DatosDivision {
	id: number;
	largo: number;
	nombre: string;
	plural: string;
	largoPadre: number | null;
	largoHijos: number | null;
}

const mapa: Record<string, string[]> = {
	CM: ["CM02","CM13","CM16","CM19","CM45"],
	
	CM02: ["CM02A","CM02B","CM02C","CM02D","CM02E"],
	CM13: ["CM13A","CM13B","CM13C","CM13D","CM13E","CM13F","CM13G"],
	CM16: ["CM16A","CM16B","CM16C","CM16D"],
	CM19: ["CM19A","CM19B","CM19C"],
	CM45: ["CM45A","CM45B","CM45C","CM45D","CM45E","CM45F"],

	CM02A: ["CM02A001","CM02A003","CM02A005","CM02A006","CM02A007","CM02A012","CM02A013","CM02A015","CM02A021","CM02A023","CM02A024","CM02A026","CM02A029","CM02A027","CM02A034","CM02A035","CM02A036","CM02A038","CM02A040","CM02A041","CM02A046","CM02A052","CM02A054","CM02A060","CM02A061","CM02A901","CM02A063","CM02A064","CM02A065","CM02A066","CM02A071","CM02A075","CM02A077","CM02A079","CM02A082","CM02A083"],
	CM02B: ["CM02B008","CM02B014","CM02B016","CM02B017","CM02B019","CM02B022","CM02B028","CM02B047","CM02B057","CM02B058","CM02B059","CM02B062","CM02B067","CM02B068","CM02B070","CM02B076","CM02B080","CM02B084","CM02B085"],
	CM02C: ["CM02C002","CM02C009","CM02C010","CM02C018","CM02C020","CM02C025","CM02C033","CM02C039","CM02C051"],
	CM02D: ["CM02D004","CM02D011","CM02D030","CM02D031","CM02D037","CM02D042","CM02D044","CM02D049","CM02D055","CM02D056","CM02D072","CM02D074","CM02D086"],
	CM02E: ["CM02E032","CM02E043","CM02E045","CM02E048","CM02E050","CM02E053","CM02E069","CM02E073","CM02E078","CM02E081"],
	CM13A: ["CM13A005","CM13A903","CM13A019","CM13A028","CM13A047","CM13A061","CM13A070","CM13A902","CM13A078","CM13A082"],
	CM13B: ["CM13B006","CM13B007","CM13B013","CM13B017","CM13B021","CM13B022","CM13B023","CM13B027","CM13B029","CM13B031","CM13B034","CM13B036","CM13B040","CM13B041","CM13B045","CM13B049","CM13B051","CM13B052","CM13B056","CM13B059","CM13B060","CM13B062","CM13B063","CM13B064","CM13B065","CM13B066","CM13B068","CM13B072","CM13B901","CM13B083","CM13B088","CM13B095"],
	CM13C: ["CM13C018","CM13C039","CM13C044","CM13C096"],
	CM13D: ["CM13D050","CM13D904","CM13D053","CM13D054","CM13D074","CM13D079","CM13D097"],
	CM13E: ["CM13E001","CM13E002","CM13E003","CM13E009","CM13E011","CM13E012","CM13E015","CM13E020","CM13E024","CM13E025","CM13E026","CM13E030","CM13E038","CM13E035","CM13E042","CM13E046","CM13E048","CM13E055","CM13E067","CM13E071","CM13E073","CM13E075","CM13E080","CM13E086","CM13E091","CM13E094"],
	CM13F: ["CM13F016","CM13F033","CM13F058","CM13F077","CM13F085","CM13F087","CM13F098"],
	CM13G: ["CM13G004","CM13G008","CM13G010","CM13G014","CM13G032","CM13G037","CM13G043","CM13G057","CM13G069","CM13G076","CM13G081","CM13G084","CM13G089","CM13G090","CM13G092","CM13G093"],
	CM16A: ["CM16A001","CM16A004","CM16A005","CM16A006","CM16A008","CM16A009","CM16A011","CM16A013","CM16A014","CM16A019","CM16A020","CM16A905","CM16A022","CM16A024","CM16A025","CM16A030","CM16A031","CM16A034","CM16A035","CM16A036","CM16A038","CM16A040","CM16A043","CM16A044","CM16A045","CM16A046","CM16A048","CM16A050","CM16A051","CM16A052","CM16A053","CM16A055","CM16A056","CM16A057","CM16A062","CM16A067","CM16A070","CM16A071","CM16A023","CM16A074","CM16A078","CM16A079","CM16A083","CM16A084","CM16A085","CM16A088","CM16A904","CM16A089","CM16A091","CM16A093","CM16A094","CM16A095","CM16A097","CM16A107","CM16A109","CM16A110","CM16A111","CM16A115","CM16A116","CM16A117","CM16A121","CM16A122","CM16A123","CM16A126","CM16A132","CM16A135","CM16A137","CM16A140","CM16A143","CM16A146","CM16A147","CM16A149","CM16A152","CM16A163","CM16A165","CM16A169","CM16A170","CM16A177","CM16A187","CM16A188","CM16A189","CM16A191","CM16A192","CM16A193","CM16A194","CM16A197","CM16A909","CM16A202","CM16A205","CM16A209","CM16A215","CM16A219","CM16A906","CM16A224","CM16A225","CM16A227","CM16A228","CM16A902","CM16A903","CM16A234","CM16A236","CM16A239","CM16A242","CM16A245","CM16A254","CM16A263","CM16A258","CM16A259","CM16A910","CM16A265","CM16A266","CM16A272","CM16A273","CM16A274","CM16A275","CM16A276","CM16A278","CM16A280"],
	CM16B: ["CM16B003","CM16B017","CM16B029","CM16B039","CM16B042","CM16B066","CM16B068","CM16B081","CM16B082","CM16B092","CM16B096","CM16B098","CM16B104","CM16B113","CM16B118","CM16B125","CM16B131","CM16B134","CM16B141","CM16B142","CM16B150","CM16B155","CM16B157","CM16B158","CM16B161","CM16B908","CM16B174","CM16B175","CM16B199","CM16B204","CM16B231","CM16B237","CM16B244","CM16B248","CM16B251","CM16B271"],
	CM16C: ["CM16C007","CM16C015","CM16C026","CM16C047","CM16C049","CM16C060","CM16C061","CM16C063","CM16C064","CM16C065","CM16C072","CM16C099","CM16C102","CM16C124","CM16C133","CM16C139","CM16C153","CM16C154","CM16C159","CM16C166","CM16C171","CM16C190","CM16C196","CM16C195","CM16C198","CM16C213","CM16C238"],
	CM16D: ["CM16D002","CM16D010","CM16D012","CM16D016","CM16D018","CM16D027","CM16D032","CM16D033","CM16D041","CM16D901","CM16D058","CM16D073","CM16D086","CM16D087","CM16D100","CM16D101","CM16D103","CM16D106","CM16D108","CM16D112","CM16D119","CM16D128","CM16D129","CM16D130","CM16D145","CM16D148","CM16D151","CM16D156","CM16D160","CM16D162","CM16D167","CM16D172","CM16D176","CM16D181","CM16D185","CM16D186","CM16D203","CM16D206","CM16D211","CM16D212","CM16D216","CM16D217","CM16D218","CM16D173","CM16D240","CM16D243","CM16D246","CM16D247","CM16D249","CM16D250","CM16D253","CM16D255","CM16D264","CM16D269","CM16D270","CM16D277","CM16D279"],
	CM19A: ["CM19A001","CM19A002","CM19A004","CM19A005","CM19A006","CM19A007","CM19A009","CM19A015","CM19A018","CM19A019","CM19A021","CM19A022","CM19A023","CM19A024","CM19A036","CM19A037","CM19A038","CM19A039","CM19A040","CM19A041","CM19A043","CM19A045","CM19A046","CM19A050","CM19A051","CM19A053","CM19A054","CM19A058","CM19A060","CM19A064","CM19A066","CM19A067","CM19A070","CM19A071","CM19A073","CM19A074","CM19A078","CM19A082","CM19A106","CM19A105","CM19A086","CM19A088","CM19A091","CM19A092","CM19A098","CM19A102","CM19A107","CM19A108","CM19A110","CM19A111","CM19A112","CM19A113","CM19A114","CM19A117","CM19A119","CM19A120","CM19A121","CM19A123","CM19A124","CM19A125","CM19A126","CM19A130","CM19A132","CM19A133","CM19A138","CM19A142","CM19A143","CM19A148","CM19A150","CM19A151","CM19A152","CM19A154","CM19A155","CM19A159","CM19A160","CM19A161","CM19A165","CM19A166","CM19A167","CM19A169","CM19A171","CM19A172","CM19A173","CM19A176","CM19A179","CM19A182","CM19A184","CM19A187","CM19A189","CM19A191","CM19A192","CM19A193","CM19A194","CM19A196","CM19A199","CM19A200","CM19A211","CM19A212","CM19A215","CM19A217","CM19A220","CM19A224","CM19A225","CM19A228","CM19A229","CM19A230","CM19A232","CM19A233","CM19A234","CM19A235","CM19A239","CM19A242","CM19A244","CM19A245","CM19A246","CM19A247","CM19A249","CM19A252","CM19A258","CM19A260","CM19A261","CM19A262","CM19A263","CM19A266","CM19A274","CM19A279","CM19A278","CM19A280","CM19A286","CM19A288","CM19A290","CM19A291","CM19A293","CM19A296","CM19A297","CM19A298","CM19A299","CM19A300","CM19A301","CM19A302","CM19A304","CM19A305","CM19A306","CM19A307","CM19A308","CM19A310","CM19A317","CM19A318","CM19A319","CM19A323","CM19A325","CM19A326","CM19A327","CM19A329","CM19A330","CM19A331","CM19A333","CM19A335"],
	CM19B: ["CM19B003","CM19B013","CM19B016","CM19B027","CM19B033","CM19B034","CM19B048","CM19B059","CM19B076","CM19B079","CM19B103","CM19B104","CM19B089","CM19B090","CM19B099","CM19B109","CM19B115","CM19B118","CM19B122","CM19B134","CM19B139","CM19B163","CM19B170","CM19B175","CM19B178","CM19B183","CM19B188","CM19B190","CM19B195","CM19B201","CM19B204","CM19B209","CM19B213","CM19B214","CM19B216","CM19B219","CM19B221","CM19B222","CM19B223","CM19B227","CM19B237","CM19B243","CM19B254","CM19B255","CM19B264","CM19B265","CM19B267","CM19B268","CM19B271","CM19B272","CM19B277","CM19B283","CM19B284","CM19B285","CM19B287","CM19B289","CM19B309","CM19B324","CM19B332"],
	CM19C: ["CM19C008","CM19C010","CM19C011","CM19C017","CM19C020","CM19C031","CM19C032","CM19C042","CM19C044","CM19C047","CM19C049","CM19C052","CM19C055","CM19C057","CM19C061","CM19C065","CM19C075","CM19C080","CM19C081","CM19C087","CM19C095","CM19C096","CM19C097","CM19C116","CM19C127","CM19C129","CM19C135","CM19C136","CM19C145","CM19C146","CM19C147","CM19C153","CM19C156","CM19C157","CM19C162","CM19C168","CM19C174","CM19C177","CM19C181","CM19C185","CM19C186","CM19C197","CM19C198","CM19C202","CM19C203","CM19C208","CM19C210","CM19C218","CM19C226","CM19C231","CM19C238","CM19C240","CM19C241","CM19C248","CM19C250","CM19C251","CM19C901","CM19C256","CM19C257","CM19C259","CM19C269","CM19C270","CM19C281","CM19C282","CM19C294","CM19C303","CM19C311","CM19C314","CM19C321","CM19C322","CM19C334"],
	CM45A: ["CM45A026","CM45A050","CM45A059","CM45A071","CM45A078","CM45A084","CM45A115","CM45A121","CM45A123","CM45A156","CM45A166","CM45A191","CM45A195","CM45A197","CM45A198","CM45A202"],
	CM45B: ["CM45B001","CM45B012","CM45B034","CM45B057","CM45B053","CM45B087","CM45B090","CM45B092","CM45B094","CM45B096","CM45B106","CM45B124","CM45B163","CM45B175","CM45B177","CM45B187","CM45B190","CM45B193","CM45B200"],
	CM45C: ["CM45C027","CM45C054","CM45C101","CM45C135","CM45C141","CM45C142","CM45C149","CM45C167","CM45C186","CM45C185","CM45C192"],
	CM45D: ["CM45D005","CM45D006","CM45D007","CM45D009","CM45D010","CM45D011","CM45D017","CM45D020","CM45D022","CM45D028","CM45D029","CM45D030","CM45D033","CM45D035","CM45D043","CM45D045","CM45D046","CM45D048","CM45D049","CM45D063","CM45D065","CM45D072","CM45D073","CM45D074","CM45D079","CM45D080","CM45D082","CM45D086","CM45D089","CM45D093","CM45D097","CM45D103","CM45D104","CM45D105","CM45D108","CM45D110","CM45D111","CM45D112","CM45D113","CM45D114","CM45D125","CM45D130","CM45D132","CM45D137","CM45D138","CM45D139","CM45D144","CM45D146","CM45D148","CM45D150","CM45D152","CM45D154","CM45D155","CM45D159","CM45D160","CM45D162","CM45D164","CM45D165","CM45D169","CM45D170","CM45D172","CM45D179","CM45D181","CM45D184","CM45D194"],
	CM45E: ["CM45E002","CM45E014","CM45E016","CM45E019","CM45E021","CM45E023","CM45E025","CM45E038","CM45E041","CM45E042","CM45E047","CM45E056","CM45E051","CM45E052","CM45E055","CM45E064","CM45E067","CM45E070","CM45E075","CM45E081","CM45E083","CM45E085","CM45E088","CM45E098","CM45E102","CM45E107","CM45E109","CM45E116","CM45E119","CM45E122","CM45E127","CM45E128","CM45E133","CM45E140","CM45E145","CM45E151","CM45E153","CM45E161","CM45E168","CM45E174","CM45E176","CM45E180","CM45E182","CM45E183","CM45E188","CM45E196","CM45E199","CM45E201","CM45E203","CM45E204","CM45E205"],
	CM45F: ["CM45F003","CM45F004","CM45F008","CM45F013","CM45F015","CM45F018","CM45F024","CM45F031","CM45F032","CM45F036","CM45F037","CM45F039","CM45F040","CM45F058","CM45F060","CM45F061","CM45F062","CM45F066","CM45F068","CM45F069","CM45F076","CM45F077","CM45F091","CM45F095","CM45F099","CM45F100","CM45F117","CM45F118","CM45F120","CM45F126","CM45F129","CM45F131","CM45F134","CM45F136","CM45F143","CM45F147","CM45F157","CM45F158","CM45F901","CM45F171","CM45F173","CM45F189"]
};

const territorios: Record<string, ElementoMapaCyan> = {
	CM: {"codigo":"CM","nombre":"Castilla-La Mancha","nombrePadre":""},
	CM02: {"codigo":"CM02","nombre":"Albacete","nombrePadre":"Castilla-La Mancha"},
	CM13: {"codigo":"CM13","nombre":"Ciudad Real","nombrePadre":"Castilla-La Mancha"},
	CM16: {"codigo":"CM16","nombre":"Cuenca","nombrePadre":"Castilla-La Mancha"},
	CM19: {"codigo":"CM19","nombre":"Guadalajara","nombrePadre":"Castilla-La Mancha"},
	CM45: {"codigo":"CM45","nombre":"Toledo","nombrePadre":"Castilla-La Mancha"},
	CM02A: {"codigo":"CM02A","nombre":"Albacete","nombrePadre":"Albacete"},
	CM02B: {"codigo":"CM02B","nombre":"Alcaraz","nombrePadre":"Albacete"},
	CM02C: {"codigo":"CM02C","nombre":"Almansa","nombrePadre":"Albacete"},
	CM02D: {"codigo":"CM02D","nombre":"Hell\u00edn","nombrePadre":"Albacete"},
	CM02E: {"codigo":"CM02E","nombre":"La Roda","nombrePadre":"Albacete"},
	CM13A: {"codigo":"CM13A","nombre":"Alc\u00e1zar de San Juan","nombrePadre":"Ciudad Real"},
	CM13B: {"codigo":"CM13B","nombre":"Ciudad Real","nombrePadre":"Ciudad Real"},
	CM13C: {"codigo":"CM13C","nombre":"Daimiel","nombrePadre":"Ciudad Real"},
	CM13D: {"codigo":"CM13D","nombre":"Manzanares","nombrePadre":"Ciudad Real"},
	CM13E: {"codigo":"CM13E","nombre":"Puertollano","nombrePadre":"Ciudad Real"},
	CM13F: {"codigo":"CM13F","nombre":"Valdepe\u00f1as","nombrePadre":"Ciudad Real"},
	CM13G: {"codigo":"CM13G","nombre":"Villanueva de los Infantes","nombrePadre":"Ciudad Real"},
	CM16A: {"codigo":"CM16A","nombre":"Cuenca","nombrePadre":"Cuenca"},
	CM16B: {"codigo":"CM16B","nombre":"Motilla del Palancar","nombrePadre":"Cuenca"},
	CM16C: {"codigo":"CM16C","nombre":"San Clemente","nombrePadre":"Cuenca"},
	CM16D: {"codigo":"CM16D","nombre":"Taranc\u00f3n","nombrePadre":"Cuenca"},
	CM19A: {"codigo":"CM19A","nombre":"Guadalajara","nombrePadre":"Guadalajara"},
	CM19B: {"codigo":"CM19B","nombre":"Molina","nombrePadre":"Guadalajara"},
	CM19C: {"codigo":"CM19C","nombre":"Sig\u00fcenza","nombrePadre":"Guadalajara"},
	CM45A: {"codigo":"CM45A","nombre":"Oca\u00f1a","nombrePadre":"Toledo"},
	CM45B: {"codigo":"CM45B","nombre":"Orgaz","nombrePadre":"Toledo"},
	CM45C: {"codigo":"CM45C","nombre":"Quintanar de la Orden","nombrePadre":"Toledo"},
	CM45D: {"codigo":"CM45D","nombre":"Talavera de la Reina","nombrePadre":"Toledo"},
	CM45E: {"codigo":"CM45E","nombre":"Toledo","nombrePadre":"Toledo"},
	CM45F: {"codigo":"CM45F","nombre":"Torrijos","nombrePadre":"Toledo"},
	CM02A001: {"codigo":"CM02A001","nombre":"Abengibre","nombrePadre":"Albacete"},
	CM02C002: {"codigo":"CM02C002","nombre":"Alatoz","nombrePadre":"Almansa"},
	CM02A003: {"codigo":"CM02A003","nombre":"Albacete","nombrePadre":"Albacete"},
	CM02D004: {"codigo":"CM02D004","nombre":"Albatana","nombrePadre":"Hell\u00edn"},
	CM02A005: {"codigo":"CM02A005","nombre":"Alborea","nombrePadre":"Albacete"},
	CM02A006: {"codigo":"CM02A006","nombre":"Alcadozo","nombrePadre":"Albacete"},
	CM02A007: {"codigo":"CM02A007","nombre":"Alcal\u00e1 del J\u00facar","nombrePadre":"Albacete"},
	CM02B008: {"codigo":"CM02B008","nombre":"Alcaraz","nombrePadre":"Alcaraz"},
	CM02C009: {"codigo":"CM02C009","nombre":"Almansa","nombrePadre":"Almansa"},
	CM02C010: {"codigo":"CM02C010","nombre":"Alpera","nombrePadre":"Almansa"},
	CM02D011: {"codigo":"CM02D011","nombre":"Ayna","nombrePadre":"Hell\u00edn"},
	CM02A012: {"codigo":"CM02A012","nombre":"Balazote","nombrePadre":"Albacete"},
	CM02B014: {"codigo":"CM02B014","nombre":"Ballestero, El","nombrePadre":"Alcaraz"},
	CM02A013: {"codigo":"CM02A013","nombre":"Balsa de Ves","nombrePadre":"Albacete"},
	CM02A015: {"codigo":"CM02A015","nombre":"Barrax","nombrePadre":"Albacete"},
	CM02B016: {"codigo":"CM02B016","nombre":"Bienservida","nombrePadre":"Alcaraz"},
	CM02B017: {"codigo":"CM02B017","nombre":"Bogarra","nombrePadre":"Alcaraz"},
	CM02C018: {"codigo":"CM02C018","nombre":"Bonete","nombrePadre":"Almansa"},
	CM02B019: {"codigo":"CM02B019","nombre":"Bonillo, El","nombrePadre":"Alcaraz"},
	CM02C020: {"codigo":"CM02C020","nombre":"Carcel\u00e9n","nombrePadre":"Almansa"},
	CM02A021: {"codigo":"CM02A021","nombre":"Casas de Juan N\u00fa\u00f1ez","nombrePadre":"Albacete"},
	CM02B022: {"codigo":"CM02B022","nombre":"Casas de L\u00e1zaro","nombrePadre":"Alcaraz"},
	CM02A023: {"codigo":"CM02A023","nombre":"Casas de Ves","nombrePadre":"Albacete"},
	CM02A024: {"codigo":"CM02A024","nombre":"Casas-Ib\u00e1\u00f1ez","nombrePadre":"Albacete"},
	CM02C025: {"codigo":"CM02C025","nombre":"Caudete","nombrePadre":"Almansa"},
	CM02A026: {"codigo":"CM02A026","nombre":"Cenizate","nombrePadre":"Albacete"},
	CM02A029: {"codigo":"CM02A029","nombre":"Chinchilla de Monte-Arag\u00f3n","nombrePadre":"Albacete"},
	CM02A027: {"codigo":"CM02A027","nombre":"Corral-Rubio","nombrePadre":"Albacete"},
	CM02B028: {"codigo":"CM02B028","nombre":"Cotillas","nombrePadre":"Alcaraz"},
	CM02D030: {"codigo":"CM02D030","nombre":"Elche de la Sierra","nombrePadre":"Hell\u00edn"},
	CM02D031: {"codigo":"CM02D031","nombre":"F\u00e9rez","nombrePadre":"Hell\u00edn"},
	CM02E032: {"codigo":"CM02E032","nombre":"Fuensanta","nombrePadre":"La Roda"},
	CM02C033: {"codigo":"CM02C033","nombre":"Fuente-\u00c1lamo","nombrePadre":"Almansa"},
	CM02A034: {"codigo":"CM02A034","nombre":"Fuentealbilla","nombrePadre":"Albacete"},
	CM02A035: {"codigo":"CM02A035","nombre":"Gineta, La","nombrePadre":"Albacete"},
	CM02A036: {"codigo":"CM02A036","nombre":"Golosalvo","nombrePadre":"Albacete"},
	CM02D037: {"codigo":"CM02D037","nombre":"Hell\u00edn","nombrePadre":"Hell\u00edn"},
	CM02A038: {"codigo":"CM02A038","nombre":"Herrera, La","nombrePadre":"Albacete"},
	CM02C039: {"codigo":"CM02C039","nombre":"Higueruela","nombrePadre":"Almansa"},
	CM02A040: {"codigo":"CM02A040","nombre":"Hoya-Gonzalo","nombrePadre":"Albacete"},
	CM02A041: {"codigo":"CM02A041","nombre":"Jorquera","nombrePadre":"Albacete"},
	CM02D042: {"codigo":"CM02D042","nombre":"Letur","nombrePadre":"Hell\u00edn"},
	CM02E043: {"codigo":"CM02E043","nombre":"Lezuza","nombrePadre":"La Roda"},
	CM02D044: {"codigo":"CM02D044","nombre":"Li\u00e9tor","nombrePadre":"Hell\u00edn"},
	CM02E045: {"codigo":"CM02E045","nombre":"Madrigueras","nombrePadre":"La Roda"},
	CM02A046: {"codigo":"CM02A046","nombre":"Mahora","nombrePadre":"Albacete"},
	CM02B047: {"codigo":"CM02B047","nombre":"Masegoso","nombrePadre":"Alcaraz"},
	CM02E048: {"codigo":"CM02E048","nombre":"Minaya","nombrePadre":"La Roda"},
	CM02D049: {"codigo":"CM02D049","nombre":"Molinicos","nombrePadre":"Hell\u00edn"},
	CM02E050: {"codigo":"CM02E050","nombre":"Montalvos","nombrePadre":"La Roda"},
	CM02C051: {"codigo":"CM02C051","nombre":"Montealegre del Castillo","nombrePadre":"Almansa"},
	CM02A052: {"codigo":"CM02A052","nombre":"Motilleja","nombrePadre":"Albacete"},
	CM02E053: {"codigo":"CM02E053","nombre":"Munera","nombrePadre":"La Roda"},
	CM02A054: {"codigo":"CM02A054","nombre":"Navas de Jorquera","nombrePadre":"Albacete"},
	CM02D055: {"codigo":"CM02D055","nombre":"Nerpio","nombrePadre":"Hell\u00edn"},
	CM02D056: {"codigo":"CM02D056","nombre":"Ontur","nombrePadre":"Hell\u00edn"},
	CM02B057: {"codigo":"CM02B057","nombre":"Ossa de Montiel","nombrePadre":"Alcaraz"},
	CM02B058: {"codigo":"CM02B058","nombre":"Paterna del Madera","nombrePadre":"Alcaraz"},
	CM02A060: {"codigo":"CM02A060","nombre":"Pe\u00f1as de San Pedro","nombrePadre":"Albacete"},
	CM02B059: {"codigo":"CM02B059","nombre":"Pe\u00f1ascosa","nombrePadre":"Alcaraz"},
	CM02A061: {"codigo":"CM02A061","nombre":"P\u00e9trola","nombrePadre":"Albacete"},
	CM02B062: {"codigo":"CM02B062","nombre":"Povedilla","nombrePadre":"Alcaraz"},
	CM02A901: {"codigo":"CM02A901","nombre":"Pozo Ca\u00f1ada","nombrePadre":"Albacete"},
	CM02A063: {"codigo":"CM02A063","nombre":"Pozohondo","nombrePadre":"Albacete"},
	CM02A064: {"codigo":"CM02A064","nombre":"Pozo-Lorente","nombrePadre":"Albacete"},
	CM02A065: {"codigo":"CM02A065","nombre":"Pozuelo","nombrePadre":"Albacete"},
	CM02A066: {"codigo":"CM02A066","nombre":"Recueja, La","nombrePadre":"Albacete"},
	CM02B067: {"codigo":"CM02B067","nombre":"Ri\u00f3par","nombrePadre":"Alcaraz"},
	CM02B068: {"codigo":"CM02B068","nombre":"Robledo","nombrePadre":"Alcaraz"},
	CM02E069: {"codigo":"CM02E069","nombre":"Roda, La","nombrePadre":"La Roda"},
	CM02B070: {"codigo":"CM02B070","nombre":"Salobre","nombrePadre":"Alcaraz"},
	CM02A071: {"codigo":"CM02A071","nombre":"San Pedro","nombrePadre":"Albacete"},
	CM02D072: {"codigo":"CM02D072","nombre":"Socovos","nombrePadre":"Hell\u00edn"},
	CM02E073: {"codigo":"CM02E073","nombre":"Tarazona de la Mancha","nombrePadre":"La Roda"},
	CM02D074: {"codigo":"CM02D074","nombre":"Tobarra","nombrePadre":"Hell\u00edn"},
	CM02A075: {"codigo":"CM02A075","nombre":"Valdeganga","nombrePadre":"Albacete"},
	CM02B076: {"codigo":"CM02B076","nombre":"Vianos","nombrePadre":"Alcaraz"},
	CM02A077: {"codigo":"CM02A077","nombre":"Villa de Ves","nombrePadre":"Albacete"},
	CM02E078: {"codigo":"CM02E078","nombre":"Villalgordo del J\u00facar","nombrePadre":"La Roda"},
	CM02A079: {"codigo":"CM02A079","nombre":"Villamalea","nombrePadre":"Albacete"},
	CM02B080: {"codigo":"CM02B080","nombre":"Villapalacios","nombrePadre":"Alcaraz"},
	CM02E081: {"codigo":"CM02E081","nombre":"Villarrobledo","nombrePadre":"La Roda"},
	CM02A082: {"codigo":"CM02A082","nombre":"Villatoya","nombrePadre":"Albacete"},
	CM02A083: {"codigo":"CM02A083","nombre":"Villavaliente","nombrePadre":"Albacete"},
	CM02B084: {"codigo":"CM02B084","nombre":"Villaverde de Guadalimar","nombrePadre":"Alcaraz"},
	CM02B085: {"codigo":"CM02B085","nombre":"Viveros","nombrePadre":"Alcaraz"},
	CM02D086: {"codigo":"CM02D086","nombre":"Yeste","nombrePadre":"Hell\u00edn"},
	CM13E001: {"codigo":"CM13E001","nombre":"Aben\u00f3jar","nombrePadre":"Puertollano"},
	CM13E002: {"codigo":"CM13E002","nombre":"Agudo","nombrePadre":"Puertollano"},
	CM13E003: {"codigo":"CM13E003","nombre":"Alamillo","nombrePadre":"Puertollano"},
	CM13G004: {"codigo":"CM13G004","nombre":"Albaladejo","nombrePadre":"Villanueva de los Infantes"},
	CM13A005: {"codigo":"CM13A005","nombre":"Alc\u00e1zar de San Juan","nombrePadre":"Alc\u00e1zar de San Juan"},
	CM13B006: {"codigo":"CM13B006","nombre":"Alcoba","nombrePadre":"Ciudad Real"},
	CM13B007: {"codigo":"CM13B007","nombre":"Alcolea de Calatrava","nombrePadre":"Ciudad Real"},
	CM13G008: {"codigo":"CM13G008","nombre":"Alcubillas","nombrePadre":"Villanueva de los Infantes"},
	CM13E009: {"codigo":"CM13E009","nombre":"Aldea del Rey","nombrePadre":"Puertollano"},
	CM13G010: {"codigo":"CM13G010","nombre":"Alhambra","nombrePadre":"Villanueva de los Infantes"},
	CM13E011: {"codigo":"CM13E011","nombre":"Almad\u00e9n","nombrePadre":"Puertollano"},
	CM13E012: {"codigo":"CM13E012","nombre":"Almadenejos","nombrePadre":"Puertollano"},
	CM13B013: {"codigo":"CM13B013","nombre":"Almagro","nombrePadre":"Ciudad Real"},
	CM13G014: {"codigo":"CM13G014","nombre":"Almedina","nombrePadre":"Villanueva de los Infantes"},
	CM13E015: {"codigo":"CM13E015","nombre":"Almod\u00f3var del Campo","nombrePadre":"Puertollano"},
	CM13F016: {"codigo":"CM13F016","nombre":"Almuradiel","nombrePadre":"Valdepe\u00f1as"},
	CM13B017: {"codigo":"CM13B017","nombre":"Anchuras","nombrePadre":"Ciudad Real"},
	CM13A903: {"codigo":"CM13A903","nombre":"Arenales de San Gregorio","nombrePadre":"Alc\u00e1zar de San Juan"},
	CM13C018: {"codigo":"CM13C018","nombre":"Arenas de San Juan","nombrePadre":"Daimiel"},
	CM13A019: {"codigo":"CM13A019","nombre":"Argamasilla de Alba","nombrePadre":"Alc\u00e1zar de San Juan"},
	CM13E020: {"codigo":"CM13E020","nombre":"Argamasilla de Calatrava","nombrePadre":"Puertollano"},
	CM13B021: {"codigo":"CM13B021","nombre":"Arroba de los Montes","nombrePadre":"Ciudad Real"},
	CM13B022: {"codigo":"CM13B022","nombre":"Ballesteros de Calatrava","nombrePadre":"Ciudad Real"},
	CM13B023: {"codigo":"CM13B023","nombre":"Bola\u00f1os de Calatrava","nombrePadre":"Ciudad Real"},
	CM13E024: {"codigo":"CM13E024","nombre":"Brazatortas","nombrePadre":"Puertollano"},
	CM13E025: {"codigo":"CM13E025","nombre":"Cabezarados","nombrePadre":"Puertollano"},
	CM13E026: {"codigo":"CM13E026","nombre":"Cabezarrubias del Puerto","nombrePadre":"Puertollano"},
	CM13B027: {"codigo":"CM13B027","nombre":"Calzada de Calatrava","nombrePadre":"Ciudad Real"},
	CM13A028: {"codigo":"CM13A028","nombre":"Campo de Criptana","nombrePadre":"Alc\u00e1zar de San Juan"},
	CM13B029: {"codigo":"CM13B029","nombre":"Ca\u00f1ada de Calatrava","nombrePadre":"Ciudad Real"},
	CM13E030: {"codigo":"CM13E030","nombre":"Caracuel de Calatrava","nombrePadre":"Puertollano"},
	CM13B031: {"codigo":"CM13B031","nombre":"Carri\u00f3n de Calatrava","nombrePadre":"Ciudad Real"},
	CM13G032: {"codigo":"CM13G032","nombre":"Carrizosa","nombrePadre":"Villanueva de los Infantes"},
	CM13F033: {"codigo":"CM13F033","nombre":"Castellar de Santiago","nombrePadre":"Valdepe\u00f1as"},
	CM13E038: {"codigo":"CM13E038","nombre":"Chill\u00f3n","nombrePadre":"Puertollano"},
	CM13B034: {"codigo":"CM13B034","nombre":"Ciudad Real","nombrePadre":"Ciudad Real"},
	CM13E035: {"codigo":"CM13E035","nombre":"Corral de Calatrava","nombrePadre":"Puertollano"},
	CM13B036: {"codigo":"CM13B036","nombre":"Cortijos, Los","nombrePadre":"Ciudad Real"},
	CM13G037: {"codigo":"CM13G037","nombre":"C\u00f3zar","nombrePadre":"Villanueva de los Infantes"},
	CM13C039: {"codigo":"CM13C039","nombre":"Daimiel","nombrePadre":"Daimiel"},
	CM13B040: {"codigo":"CM13B040","nombre":"Fern\u00e1n Caballero","nombrePadre":"Ciudad Real"},
	CM13B041: {"codigo":"CM13B041","nombre":"Fontanarejo","nombrePadre":"Ciudad Real"},
	CM13E042: {"codigo":"CM13E042","nombre":"Fuencaliente","nombrePadre":"Puertollano"},
	CM13G043: {"codigo":"CM13G043","nombre":"Fuenllana","nombrePadre":"Villanueva de los Infantes"},
	CM13C044: {"codigo":"CM13C044","nombre":"Fuente el Fresno","nombrePadre":"Daimiel"},
	CM13B045: {"codigo":"CM13B045","nombre":"Gran\u00e1tula de Calatrava","nombrePadre":"Ciudad Real"},
	CM13E046: {"codigo":"CM13E046","nombre":"Guadalmez","nombrePadre":"Puertollano"},
	CM13A047: {"codigo":"CM13A047","nombre":"Herencia","nombrePadre":"Alc\u00e1zar de San Juan"},
	CM13E048: {"codigo":"CM13E048","nombre":"Hinojosas de Calatrava","nombrePadre":"Puertollano"},
	CM13B049: {"codigo":"CM13B049","nombre":"Horcajo de los Montes","nombrePadre":"Ciudad Real"},
	CM13D050: {"codigo":"CM13D050","nombre":"Labores, Las","nombrePadre":"Manzanares"},
	CM13D904: {"codigo":"CM13D904","nombre":"Llanos del Caudillo","nombrePadre":"Manzanares"},
	CM13B051: {"codigo":"CM13B051","nombre":"Luciana","nombrePadre":"Ciudad Real"},
	CM13B052: {"codigo":"CM13B052","nombre":"Malag\u00f3n","nombrePadre":"Ciudad Real"},
	CM13D053: {"codigo":"CM13D053","nombre":"Manzanares","nombrePadre":"Manzanares"},
	CM13D054: {"codigo":"CM13D054","nombre":"Membrilla","nombrePadre":"Manzanares"},
	CM13E055: {"codigo":"CM13E055","nombre":"Mestanza","nombrePadre":"Puertollano"},
	CM13B056: {"codigo":"CM13B056","nombre":"Miguelturra","nombrePadre":"Ciudad Real"},
	CM13G057: {"codigo":"CM13G057","nombre":"Montiel","nombrePadre":"Villanueva de los Infantes"},
	CM13F058: {"codigo":"CM13F058","nombre":"Moral de Calatrava","nombrePadre":"Valdepe\u00f1as"},
	CM13B059: {"codigo":"CM13B059","nombre":"Navalpino","nombrePadre":"Ciudad Real"},
	CM13B060: {"codigo":"CM13B060","nombre":"Navas de Estena","nombrePadre":"Ciudad Real"},
	CM13A061: {"codigo":"CM13A061","nombre":"Pedro Mu\u00f1oz","nombrePadre":"Alc\u00e1zar de San Juan"},
	CM13B062: {"codigo":"CM13B062","nombre":"Pic\u00f3n","nombrePadre":"Ciudad Real"},
	CM13B063: {"codigo":"CM13B063","nombre":"Piedrabuena","nombrePadre":"Ciudad Real"},
	CM13B064: {"codigo":"CM13B064","nombre":"Poblete","nombrePadre":"Ciudad Real"},
	CM13B065: {"codigo":"CM13B065","nombre":"Porzuna","nombrePadre":"Ciudad Real"},
	CM13B066: {"codigo":"CM13B066","nombre":"Pozuelo de Calatrava","nombrePadre":"Ciudad Real"},
	CM13E067: {"codigo":"CM13E067","nombre":"Pozuelos de Calatrava, Los","nombrePadre":"Puertollano"},
	CM13B068: {"codigo":"CM13B068","nombre":"Puebla de Don Rodrigo","nombrePadre":"Ciudad Real"},
	CM13G069: {"codigo":"CM13G069","nombre":"Puebla del Pr\u00edncipe","nombrePadre":"Villanueva de los Infantes"},
	CM13A070: {"codigo":"CM13A070","nombre":"Puerto L\u00e1pice","nombrePadre":"Alc\u00e1zar de San Juan"},
	CM13E071: {"codigo":"CM13E071","nombre":"Puertollano","nombrePadre":"Puertollano"},
	CM13B072: {"codigo":"CM13B072","nombre":"Retuerta del Bullaque","nombrePadre":"Ciudad Real"},
	CM13B901: {"codigo":"CM13B901","nombre":"Robledo, El","nombrePadre":"Ciudad Real"},
	CM13A902: {"codigo":"CM13A902","nombre":"Ruidera","nombrePadre":"Alc\u00e1zar de San Juan"},
	CM13E073: {"codigo":"CM13E073","nombre":"Saceruela","nombrePadre":"Puertollano"},
	CM13D074: {"codigo":"CM13D074","nombre":"San Carlos del Valle","nombrePadre":"Manzanares"},
	CM13E075: {"codigo":"CM13E075","nombre":"San Lorenzo de Calatrava","nombrePadre":"Puertollano"},
	CM13G076: {"codigo":"CM13G076","nombre":"Santa Cruz de los C\u00e1\u00f1amos","nombrePadre":"Villanueva de los Infantes"},
	CM13F077: {"codigo":"CM13F077","nombre":"Santa Cruz de Mudela","nombrePadre":"Valdepe\u00f1as"},
	CM13A078: {"codigo":"CM13A078","nombre":"Socu\u00e9llamos","nombrePadre":"Alc\u00e1zar de San Juan"},
	CM13E080: {"codigo":"CM13E080","nombre":"Solana del Pino","nombrePadre":"Puertollano"},
	CM13D079: {"codigo":"CM13D079","nombre":"Solana, La","nombrePadre":"Manzanares"},
	CM13G081: {"codigo":"CM13G081","nombre":"Terrinches","nombrePadre":"Villanueva de los Infantes"},
	CM13A082: {"codigo":"CM13A082","nombre":"Tomelloso","nombrePadre":"Alc\u00e1zar de San Juan"},
	CM13B083: {"codigo":"CM13B083","nombre":"Torralba de Calatrava","nombrePadre":"Ciudad Real"},
	CM13G084: {"codigo":"CM13G084","nombre":"Torre de Juan Abad","nombrePadre":"Villanueva de los Infantes"},
	CM13F085: {"codigo":"CM13F085","nombre":"Torrenueva","nombrePadre":"Valdepe\u00f1as"},
	CM13E086: {"codigo":"CM13E086","nombre":"Valdemanco del Esteras","nombrePadre":"Puertollano"},
	CM13F087: {"codigo":"CM13F087","nombre":"Valdepe\u00f1as","nombrePadre":"Valdepe\u00f1as"},
	CM13B088: {"codigo":"CM13B088","nombre":"Valenzuela de Calatrava","nombrePadre":"Ciudad Real"},
	CM13G089: {"codigo":"CM13G089","nombre":"Villahermosa","nombrePadre":"Villanueva de los Infantes"},
	CM13G090: {"codigo":"CM13G090","nombre":"Villamanrique","nombrePadre":"Villanueva de los Infantes"},
	CM13E091: {"codigo":"CM13E091","nombre":"Villamayor de Calatrava","nombrePadre":"Puertollano"},
	CM13G092: {"codigo":"CM13G092","nombre":"Villanueva de la Fuente","nombrePadre":"Villanueva de los Infantes"},
	CM13G093: {"codigo":"CM13G093","nombre":"Villanueva de los Infantes","nombrePadre":"Villanueva de los Infantes"},
	CM13E094: {"codigo":"CM13E094","nombre":"Villanueva de San Carlos","nombrePadre":"Puertollano"},
	CM13B095: {"codigo":"CM13B095","nombre":"Villar del Pozo","nombrePadre":"Ciudad Real"},
	CM13C096: {"codigo":"CM13C096","nombre":"Villarrubia de los Ojos","nombrePadre":"Daimiel"},
	CM13D097: {"codigo":"CM13D097","nombre":"Villarta de San Juan","nombrePadre":"Manzanares"},
	CM13F098: {"codigo":"CM13F098","nombre":"Viso del Marqu\u00e9s","nombrePadre":"Valdepe\u00f1as"},
	CM16A001: {"codigo":"CM16A001","nombre":"Abia de la Obispal\u00eda","nombrePadre":"Cuenca"},
	CM16D002: {"codigo":"CM16D002","nombre":"Acebr\u00f3n, El","nombrePadre":"Taranc\u00f3n"},
	CM16B003: {"codigo":"CM16B003","nombre":"Alarc\u00f3n","nombrePadre":"Motilla del Palancar"},
	CM16A004: {"codigo":"CM16A004","nombre":"Albaladejo del Cuende","nombrePadre":"Cuenca"},
	CM16A005: {"codigo":"CM16A005","nombre":"Albalate de las Nogueras","nombrePadre":"Cuenca"},
	CM16A006: {"codigo":"CM16A006","nombre":"Albendea","nombrePadre":"Cuenca"},
	CM16C007: {"codigo":"CM16C007","nombre":"Alberca de Z\u00e1ncara, La","nombrePadre":"San Clemente"},
	CM16A008: {"codigo":"CM16A008","nombre":"Alcal\u00e1 de la Vega","nombrePadre":"Cuenca"},
	CM16A009: {"codigo":"CM16A009","nombre":"Alcantud","nombrePadre":"Cuenca"},
	CM16D010: {"codigo":"CM16D010","nombre":"Alc\u00e1zar del Rey","nombrePadre":"Taranc\u00f3n"},
	CM16A011: {"codigo":"CM16A011","nombre":"Alcohujate","nombrePadre":"Cuenca"},
	CM16D012: {"codigo":"CM16D012","nombre":"Alconchel de la Estrella","nombrePadre":"Taranc\u00f3n"},
	CM16A013: {"codigo":"CM16A013","nombre":"Algarra","nombrePadre":"Cuenca"},
	CM16A014: {"codigo":"CM16A014","nombre":"Aliaguilla","nombrePadre":"Cuenca"},
	CM16C015: {"codigo":"CM16C015","nombre":"Almarcha, La","nombrePadre":"San Clemente"},
	CM16D016: {"codigo":"CM16D016","nombre":"Almendros","nombrePadre":"Taranc\u00f3n"},
	CM16B017: {"codigo":"CM16B017","nombre":"Almod\u00f3var del Pinar","nombrePadre":"Motilla del Palancar"},
	CM16D018: {"codigo":"CM16D018","nombre":"Almonacid del Marquesado","nombrePadre":"Taranc\u00f3n"},
	CM16A019: {"codigo":"CM16A019","nombre":"Altarejos","nombrePadre":"Cuenca"},
	CM16A020: {"codigo":"CM16A020","nombre":"Arandilla del Arroyo","nombrePadre":"Cuenca"},
	CM16A905: {"codigo":"CM16A905","nombre":"Arcas","nombrePadre":"Cuenca"},
	CM16A022: {"codigo":"CM16A022","nombre":"Arcos de la Sierra","nombrePadre":"Cuenca"},
	CM16A024: {"codigo":"CM16A024","nombre":"Arguisuelas","nombrePadre":"Cuenca"},
	CM16A025: {"codigo":"CM16A025","nombre":"Arrancacepas","nombrePadre":"Cuenca"},
	CM16C026: {"codigo":"CM16C026","nombre":"Atalaya del Ca\u00f1avate","nombrePadre":"San Clemente"},
	CM16D027: {"codigo":"CM16D027","nombre":"Barajas de Melo","nombrePadre":"Taranc\u00f3n"},
	CM16B029: {"codigo":"CM16B029","nombre":"Barch\u00edn del Hoyo","nombrePadre":"Motilla del Palancar"},
	CM16A030: {"codigo":"CM16A030","nombre":"Bascu\u00f1ana de San Pedro","nombrePadre":"Cuenca"},
	CM16A031: {"codigo":"CM16A031","nombre":"Beamud","nombrePadre":"Cuenca"},
	CM16D032: {"codigo":"CM16D032","nombre":"Belinch\u00f3n","nombrePadre":"Taranc\u00f3n"},
	CM16D033: {"codigo":"CM16D033","nombre":"Belmonte","nombrePadre":"Taranc\u00f3n"},
	CM16A034: {"codigo":"CM16A034","nombre":"Belmontejo","nombrePadre":"Cuenca"},
	CM16A035: {"codigo":"CM16A035","nombre":"Beteta","nombrePadre":"Cuenca"},
	CM16A036: {"codigo":"CM16A036","nombre":"Boniches","nombrePadre":"Cuenca"},
	CM16A038: {"codigo":"CM16A038","nombre":"Buciegas","nombrePadre":"Cuenca"},
	CM16B039: {"codigo":"CM16B039","nombre":"Buenache de Alarc\u00f3n","nombrePadre":"Motilla del Palancar"},
	CM16A040: {"codigo":"CM16A040","nombre":"Buenache de la Sierra","nombrePadre":"Cuenca"},
	CM16D041: {"codigo":"CM16D041","nombre":"Buend\u00eda","nombrePadre":"Taranc\u00f3n"},
	CM16B042: {"codigo":"CM16B042","nombre":"Campillo de Altobuey","nombrePadre":"Motilla del Palancar"},
	CM16A043: {"codigo":"CM16A043","nombre":"Campillos-Paravientos","nombrePadre":"Cuenca"},
	CM16A044: {"codigo":"CM16A044","nombre":"Campillos-Sierra","nombrePadre":"Cuenca"},
	CM16D901: {"codigo":"CM16D901","nombre":"Campos del Para\u00edso","nombrePadre":"Taranc\u00f3n"},
	CM16A045: {"codigo":"CM16A045","nombre":"Canalejas del Arroyo","nombrePadre":"Cuenca"},
	CM16A046: {"codigo":"CM16A046","nombre":"Ca\u00f1ada del Hoyo","nombrePadre":"Cuenca"},
	CM16C047: {"codigo":"CM16C047","nombre":"Ca\u00f1ada Juncosa","nombrePadre":"San Clemente"},
	CM16A048: {"codigo":"CM16A048","nombre":"Ca\u00f1amares","nombrePadre":"Cuenca"},
	CM16C049: {"codigo":"CM16C049","nombre":"Ca\u00f1avate, El","nombrePadre":"San Clemente"},
	CM16A050: {"codigo":"CM16A050","nombre":"Ca\u00f1averas","nombrePadre":"Cuenca"},
	CM16A051: {"codigo":"CM16A051","nombre":"Ca\u00f1averuelas","nombrePadre":"Cuenca"},
	CM16A052: {"codigo":"CM16A052","nombre":"Ca\u00f1ete","nombrePadre":"Cuenca"},
	CM16A053: {"codigo":"CM16A053","nombre":"Ca\u00f1izares","nombrePadre":"Cuenca"},
	CM16A055: {"codigo":"CM16A055","nombre":"Carboneras de Guadaza\u00f3n","nombrePadre":"Cuenca"},
	CM16A056: {"codigo":"CM16A056","nombre":"Cardenete","nombrePadre":"Cuenca"},
	CM16A057: {"codigo":"CM16A057","nombre":"Carrascosa","nombrePadre":"Cuenca"},
	CM16D058: {"codigo":"CM16D058","nombre":"Carrascosa de Haro","nombrePadre":"Taranc\u00f3n"},
	CM16C060: {"codigo":"CM16C060","nombre":"Casas de Ben\u00edtez","nombrePadre":"San Clemente"},
	CM16C061: {"codigo":"CM16C061","nombre":"Casas de Fernando Alonso","nombrePadre":"San Clemente"},
	CM16A062: {"codigo":"CM16A062","nombre":"Casas de Garcimolina","nombrePadre":"Cuenca"},
	CM16C063: {"codigo":"CM16C063","nombre":"Casas de Guijarro","nombrePadre":"San Clemente"},
	CM16C064: {"codigo":"CM16C064","nombre":"Casas de Haro","nombrePadre":"San Clemente"},
	CM16C065: {"codigo":"CM16C065","nombre":"Casas de los Pinos","nombrePadre":"San Clemente"},
	CM16B066: {"codigo":"CM16B066","nombre":"Casasimarro","nombrePadre":"Motilla del Palancar"},
	CM16A067: {"codigo":"CM16A067","nombre":"Castej\u00f3n","nombrePadre":"Cuenca"},
	CM16B068: {"codigo":"CM16B068","nombre":"Castillejo de Iniesta","nombrePadre":"Motilla del Palancar"},
	CM16A070: {"codigo":"CM16A070","nombre":"Castillejo-Sierra","nombrePadre":"Cuenca"},
	CM16C072: {"codigo":"CM16C072","nombre":"Castillo de Garcimu\u00f1oz","nombrePadre":"San Clemente"},
	CM16A071: {"codigo":"CM16A071","nombre":"Castillo-Albar\u00e1\u00f1ez","nombrePadre":"Cuenca"},
	CM16D073: {"codigo":"CM16D073","nombre":"Cervera del Llano","nombrePadre":"Taranc\u00f3n"},
	CM16A023: {"codigo":"CM16A023","nombre":"Chillar\u00f3n de Cuenca","nombrePadre":"Cuenca"},
	CM16B081: {"codigo":"CM16B081","nombre":"Chumillas","nombrePadre":"Motilla del Palancar"},
	CM16A074: {"codigo":"CM16A074","nombre":"Cierva, La","nombrePadre":"Cuenca"},
	CM16A078: {"codigo":"CM16A078","nombre":"Cuenca","nombrePadre":"Cuenca"},
	CM16A079: {"codigo":"CM16A079","nombre":"Cueva del Hierro","nombrePadre":"Cuenca"},
	CM16B082: {"codigo":"CM16B082","nombre":"Engu\u00eddanos","nombrePadre":"Motilla del Palancar"},
	CM16A083: {"codigo":"CM16A083","nombre":"Fresneda de Altarejos","nombrePadre":"Cuenca"},
	CM16A084: {"codigo":"CM16A084","nombre":"Fresneda de la Sierra","nombrePadre":"Cuenca"},
	CM16A085: {"codigo":"CM16A085","nombre":"Frontera, La","nombrePadre":"Cuenca"},
	CM16D086: {"codigo":"CM16D086","nombre":"Fuente de Pedro Naharro","nombrePadre":"Taranc\u00f3n"},
	CM16D087: {"codigo":"CM16D087","nombre":"Fuentelespino de Haro","nombrePadre":"Taranc\u00f3n"},
	CM16A088: {"codigo":"CM16A088","nombre":"Fuentelespino de Moya","nombrePadre":"Cuenca"},
	CM16A904: {"codigo":"CM16A904","nombre":"Fuentenava de J\u00e1baga","nombrePadre":"Cuenca"},
	CM16A089: {"codigo":"CM16A089","nombre":"Fuentes","nombrePadre":"Cuenca"},
	CM16A091: {"codigo":"CM16A091","nombre":"Fuertescusa","nombrePadre":"Cuenca"},
	CM16B092: {"codigo":"CM16B092","nombre":"Gabald\u00f3n","nombrePadre":"Motilla del Palancar"},
	CM16A093: {"codigo":"CM16A093","nombre":"Garaballa","nombrePadre":"Cuenca"},
	CM16A094: {"codigo":"CM16A094","nombre":"Gascue\u00f1a","nombrePadre":"Cuenca"},
	CM16A095: {"codigo":"CM16A095","nombre":"Graja de Campalbo","nombrePadre":"Cuenca"},
	CM16B096: {"codigo":"CM16B096","nombre":"Graja de Iniesta","nombrePadre":"Motilla del Palancar"},
	CM16A097: {"codigo":"CM16A097","nombre":"Henarejos","nombrePadre":"Cuenca"},
	CM16B098: {"codigo":"CM16B098","nombre":"Herrumblar, El","nombrePadre":"Motilla del Palancar"},
	CM16C099: {"codigo":"CM16C099","nombre":"Hinojosa, La","nombrePadre":"San Clemente"},
	CM16D100: {"codigo":"CM16D100","nombre":"Hinojosos, Los","nombrePadre":"Taranc\u00f3n"},
	CM16D101: {"codigo":"CM16D101","nombre":"Hito, El","nombrePadre":"Taranc\u00f3n"},
	CM16C102: {"codigo":"CM16C102","nombre":"Honrubia","nombrePadre":"San Clemente"},
	CM16D103: {"codigo":"CM16D103","nombre":"Hontanaya","nombrePadre":"Taranc\u00f3n"},
	CM16B104: {"codigo":"CM16B104","nombre":"Hontecillas","nombrePadre":"Motilla del Palancar"},
	CM16D106: {"codigo":"CM16D106","nombre":"Horcajo de Santiago","nombrePadre":"Taranc\u00f3n"},
	CM16A107: {"codigo":"CM16A107","nombre":"Hu\u00e9lamo","nombrePadre":"Cuenca"},
	CM16D108: {"codigo":"CM16D108","nombre":"Huelves","nombrePadre":"Taranc\u00f3n"},
	CM16A109: {"codigo":"CM16A109","nombre":"Hu\u00e9rguina","nombrePadre":"Cuenca"},
	CM16A110: {"codigo":"CM16A110","nombre":"Huerta de la Obispal\u00eda","nombrePadre":"Cuenca"},
	CM16A111: {"codigo":"CM16A111","nombre":"Huerta del Marquesado","nombrePadre":"Cuenca"},
	CM16D112: {"codigo":"CM16D112","nombre":"Huete","nombrePadre":"Taranc\u00f3n"},
	CM16B113: {"codigo":"CM16B113","nombre":"Iniesta","nombrePadre":"Motilla del Palancar"},
	CM16A115: {"codigo":"CM16A115","nombre":"Laguna del Marquesado","nombrePadre":"Cuenca"},
	CM16A116: {"codigo":"CM16A116","nombre":"Lagunaseca","nombrePadre":"Cuenca"},
	CM16A117: {"codigo":"CM16A117","nombre":"Landete","nombrePadre":"Cuenca"},
	CM16B118: {"codigo":"CM16B118","nombre":"Leda\u00f1a","nombrePadre":"Motilla del Palancar"},
	CM16D119: {"codigo":"CM16D119","nombre":"Leganiel","nombrePadre":"Taranc\u00f3n"},
	CM16A121: {"codigo":"CM16A121","nombre":"Majadas, Las","nombrePadre":"Cuenca"},
	CM16A122: {"codigo":"CM16A122","nombre":"Mariana","nombrePadre":"Cuenca"},
	CM16A123: {"codigo":"CM16A123","nombre":"Masegosa","nombrePadre":"Cuenca"},
	CM16C124: {"codigo":"CM16C124","nombre":"Mesas, Las","nombrePadre":"San Clemente"},
	CM16B125: {"codigo":"CM16B125","nombre":"Minglanilla","nombrePadre":"Motilla del Palancar"},
	CM16A126: {"codigo":"CM16A126","nombre":"Mira","nombrePadre":"Cuenca"},
	CM16D128: {"codigo":"CM16D128","nombre":"Monreal del Llano","nombrePadre":"Taranc\u00f3n"},
	CM16D129: {"codigo":"CM16D129","nombre":"Montalbanejo","nombrePadre":"Taranc\u00f3n"},
	CM16D130: {"codigo":"CM16D130","nombre":"Montalbo","nombrePadre":"Taranc\u00f3n"},
	CM16B131: {"codigo":"CM16B131","nombre":"Monteagudo de las Salinas","nombrePadre":"Motilla del Palancar"},
	CM16A132: {"codigo":"CM16A132","nombre":"Mota de Altarejos","nombrePadre":"Cuenca"},
	CM16C133: {"codigo":"CM16C133","nombre":"Mota del Cuervo","nombrePadre":"San Clemente"},
	CM16B134: {"codigo":"CM16B134","nombre":"Motilla del Palancar","nombrePadre":"Motilla del Palancar"},
	CM16A135: {"codigo":"CM16A135","nombre":"Moya","nombrePadre":"Cuenca"},
	CM16A137: {"codigo":"CM16A137","nombre":"Narboneta","nombrePadre":"Cuenca"},
	CM16C139: {"codigo":"CM16C139","nombre":"Olivares de J\u00facar","nombrePadre":"San Clemente"},
	CM16A140: {"codigo":"CM16A140","nombre":"Olmeda de la Cuesta","nombrePadre":"Cuenca"},
	CM16B141: {"codigo":"CM16B141","nombre":"Olmeda del Rey","nombrePadre":"Motilla del Palancar"},
	CM16B142: {"codigo":"CM16B142","nombre":"Olmedilla de Alarc\u00f3n","nombrePadre":"Motilla del Palancar"},
	CM16A143: {"codigo":"CM16A143","nombre":"Olmedilla de Eliz","nombrePadre":"Cuenca"},
	CM16D145: {"codigo":"CM16D145","nombre":"Osa de la Vega","nombrePadre":"Taranc\u00f3n"},
	CM16A146: {"codigo":"CM16A146","nombre":"Pajar\u00f3n","nombrePadre":"Cuenca"},
	CM16A147: {"codigo":"CM16A147","nombre":"Pajaroncillo","nombrePadre":"Cuenca"},
	CM16D148: {"codigo":"CM16D148","nombre":"Palomares del Campo","nombrePadre":"Taranc\u00f3n"},
	CM16A149: {"codigo":"CM16A149","nombre":"Palomera","nombrePadre":"Cuenca"},
	CM16B150: {"codigo":"CM16B150","nombre":"Paracuellos","nombrePadre":"Motilla del Palancar"},
	CM16D151: {"codigo":"CM16D151","nombre":"Paredes","nombrePadre":"Taranc\u00f3n"},
	CM16A152: {"codigo":"CM16A152","nombre":"Parra de las Vegas, La","nombrePadre":"Cuenca"},
	CM16C153: {"codigo":"CM16C153","nombre":"Pedernoso, El","nombrePadre":"San Clemente"},
	CM16C154: {"codigo":"CM16C154","nombre":"Pedro\u00f1eras, Las","nombrePadre":"San Clemente"},
	CM16B155: {"codigo":"CM16B155","nombre":"Peral, El","nombrePadre":"Motilla del Palancar"},
	CM16D156: {"codigo":"CM16D156","nombre":"Peraleja, La","nombrePadre":"Taranc\u00f3n"},
	CM16B157: {"codigo":"CM16B157","nombre":"Pesquera, La","nombrePadre":"Motilla del Palancar"},
	CM16B158: {"codigo":"CM16B158","nombre":"Picazo, El","nombrePadre":"Motilla del Palancar"},
	CM16C159: {"codigo":"CM16C159","nombre":"Pinarejo","nombrePadre":"San Clemente"},
	CM16D160: {"codigo":"CM16D160","nombre":"Pineda de Gig\u00fcela","nombrePadre":"Taranc\u00f3n"},
	CM16B161: {"codigo":"CM16B161","nombre":"Piqueras del Castillo","nombrePadre":"Motilla del Palancar"},
	CM16D162: {"codigo":"CM16D162","nombre":"Portalrubio de Guadamejud","nombrePadre":"Taranc\u00f3n"},
	CM16A163: {"codigo":"CM16A163","nombre":"Portilla","nombrePadre":"Cuenca"},
	CM16A165: {"codigo":"CM16A165","nombre":"Poyatos","nombrePadre":"Cuenca"},
	CM16C166: {"codigo":"CM16C166","nombre":"Pozoamargo","nombrePadre":"San Clemente"},
	CM16B908: {"codigo":"CM16B908","nombre":"Pozorrubielos de la Mancha","nombrePadre":"Motilla del Palancar"},
	CM16D167: {"codigo":"CM16D167","nombre":"Pozorrubio de Santiago","nombrePadre":"Taranc\u00f3n"},
	CM16A169: {"codigo":"CM16A169","nombre":"Pozuelo, El","nombrePadre":"Cuenca"},
	CM16A170: {"codigo":"CM16A170","nombre":"Priego","nombrePadre":"Cuenca"},
	CM16C171: {"codigo":"CM16C171","nombre":"Provencio, El","nombrePadre":"San Clemente"},
	CM16D172: {"codigo":"CM16D172","nombre":"Puebla de Almenara","nombrePadre":"Taranc\u00f3n"},
	CM16B174: {"codigo":"CM16B174","nombre":"Puebla del Salvador","nombrePadre":"Motilla del Palancar"},
	CM16B175: {"codigo":"CM16B175","nombre":"Quintanar del Rey","nombrePadre":"Motilla del Palancar"},
	CM16D176: {"codigo":"CM16D176","nombre":"Rada de Haro","nombrePadre":"Taranc\u00f3n"},
	CM16A177: {"codigo":"CM16A177","nombre":"Re\u00edllo","nombrePadre":"Cuenca"},
	CM16D181: {"codigo":"CM16D181","nombre":"Rozal\u00e9n del Monte","nombrePadre":"Taranc\u00f3n"},
	CM16D185: {"codigo":"CM16D185","nombre":"Saceda-Trasierra","nombrePadre":"Taranc\u00f3n"},
	CM16D186: {"codigo":"CM16D186","nombre":"Saelices","nombrePadre":"Taranc\u00f3n"},
	CM16A187: {"codigo":"CM16A187","nombre":"Salinas del Manzano","nombrePadre":"Cuenca"},
	CM16A188: {"codigo":"CM16A188","nombre":"Salmeroncillos","nombrePadre":"Cuenca"},
	CM16A189: {"codigo":"CM16A189","nombre":"Salvaca\u00f1ete","nombrePadre":"Cuenca"},
	CM16C190: {"codigo":"CM16C190","nombre":"San Clemente","nombrePadre":"San Clemente"},
	CM16A191: {"codigo":"CM16A191","nombre":"San Lorenzo de la Parrilla","nombrePadre":"Cuenca"},
	CM16A192: {"codigo":"CM16A192","nombre":"San Mart\u00edn de Boniches","nombrePadre":"Cuenca"},
	CM16A193: {"codigo":"CM16A193","nombre":"San Pedro Palmiches","nombrePadre":"Cuenca"},
	CM16A194: {"codigo":"CM16A194","nombre":"Santa Cruz de Moya","nombrePadre":"Cuenca"},
	CM16C196: {"codigo":"CM16C196","nombre":"Santa Mar\u00eda de los Llanos","nombrePadre":"San Clemente"},
	CM16C195: {"codigo":"CM16C195","nombre":"Santa Mar\u00eda del Campo Rus","nombrePadre":"San Clemente"},
	CM16A197: {"codigo":"CM16A197","nombre":"Santa Mar\u00eda del Val","nombrePadre":"Cuenca"},
	CM16C198: {"codigo":"CM16C198","nombre":"Sisante","nombrePadre":"San Clemente"},
	CM16B199: {"codigo":"CM16B199","nombre":"Solera de Gabald\u00f3n","nombrePadre":"Motilla del Palancar"},
	CM16A909: {"codigo":"CM16A909","nombre":"Sotorribas","nombrePadre":"Cuenca"},
	CM16A202: {"codigo":"CM16A202","nombre":"Talayuelas","nombrePadre":"Cuenca"},
	CM16D203: {"codigo":"CM16D203","nombre":"Taranc\u00f3n","nombrePadre":"Taranc\u00f3n"},
	CM16B204: {"codigo":"CM16B204","nombre":"T\u00e9bar","nombrePadre":"Motilla del Palancar"},
	CM16A205: {"codigo":"CM16A205","nombre":"Tejadillos","nombrePadre":"Cuenca"},
	CM16D206: {"codigo":"CM16D206","nombre":"Tinajas","nombrePadre":"Taranc\u00f3n"},
	CM16A209: {"codigo":"CM16A209","nombre":"Torralba","nombrePadre":"Cuenca"},
	CM16D211: {"codigo":"CM16D211","nombre":"Torrejoncillo del Rey","nombrePadre":"Taranc\u00f3n"},
	CM16D212: {"codigo":"CM16D212","nombre":"Torrubia del Campo","nombrePadre":"Taranc\u00f3n"},
	CM16C213: {"codigo":"CM16C213","nombre":"Torrubia del Castillo","nombrePadre":"San Clemente"},
	CM16A215: {"codigo":"CM16A215","nombre":"Tragacete","nombrePadre":"Cuenca"},
	CM16D216: {"codigo":"CM16D216","nombre":"Tresjuncos","nombrePadre":"Taranc\u00f3n"},
	CM16D217: {"codigo":"CM16D217","nombre":"Tribaldos","nombrePadre":"Taranc\u00f3n"},
	CM16D218: {"codigo":"CM16D218","nombre":"Ucl\u00e9s","nombrePadre":"Taranc\u00f3n"},
	CM16A219: {"codigo":"CM16A219","nombre":"U\u00f1a","nombrePadre":"Cuenca"},
	CM16A906: {"codigo":"CM16A906","nombre":"Valdecolmenas, Los","nombrePadre":"Cuenca"},
	CM16A224: {"codigo":"CM16A224","nombre":"Valdemeca","nombrePadre":"Cuenca"},
	CM16A225: {"codigo":"CM16A225","nombre":"Valdemorillo de la Sierra","nombrePadre":"Cuenca"},
	CM16A227: {"codigo":"CM16A227","nombre":"Valdemoro-Sierra","nombrePadre":"Cuenca"},
	CM16A228: {"codigo":"CM16A228","nombre":"Valdeolivas","nombrePadre":"Cuenca"},
	CM16A902: {"codigo":"CM16A902","nombre":"Valdet\u00f3rtola","nombrePadre":"Cuenca"},
	CM16A903: {"codigo":"CM16A903","nombre":"Valeras, Las","nombrePadre":"Cuenca"},
	CM16B231: {"codigo":"CM16B231","nombre":"Valhermoso de la Fuente","nombrePadre":"Motilla del Palancar"},
	CM16D173: {"codigo":"CM16D173","nombre":"Valle de Altomira, El","nombrePadre":"Taranc\u00f3n"},
	CM16A234: {"codigo":"CM16A234","nombre":"Valsalobre","nombrePadre":"Cuenca"},
	CM16A236: {"codigo":"CM16A236","nombre":"Valverde de J\u00facar","nombrePadre":"Cuenca"},
	CM16B237: {"codigo":"CM16B237","nombre":"Valverdejo","nombrePadre":"Motilla del Palancar"},
	CM16C238: {"codigo":"CM16C238","nombre":"Vara de Rey","nombrePadre":"San Clemente"},
	CM16A239: {"codigo":"CM16A239","nombre":"Vega del Codorno","nombrePadre":"Cuenca"},
	CM16D240: {"codigo":"CM16D240","nombre":"Vellisca","nombrePadre":"Taranc\u00f3n"},
	CM16A242: {"codigo":"CM16A242","nombre":"Villaconejos de Trabaque","nombrePadre":"Cuenca"},
	CM16D243: {"codigo":"CM16D243","nombre":"Villaescusa de Haro","nombrePadre":"Taranc\u00f3n"},
	CM16B244: {"codigo":"CM16B244","nombre":"Villagarc\u00eda del Llano","nombrePadre":"Motilla del Palancar"},
	CM16A245: {"codigo":"CM16A245","nombre":"Villalba de la Sierra","nombrePadre":"Cuenca"},
	CM16D246: {"codigo":"CM16D246","nombre":"Villalba del Rey","nombrePadre":"Taranc\u00f3n"},
	CM16D247: {"codigo":"CM16D247","nombre":"Villalgordo del Marquesado","nombrePadre":"Taranc\u00f3n"},
	CM16B248: {"codigo":"CM16B248","nombre":"Villalpardo","nombrePadre":"Motilla del Palancar"},
	CM16D249: {"codigo":"CM16D249","nombre":"Villamayor de Santiago","nombrePadre":"Taranc\u00f3n"},
	CM16D250: {"codigo":"CM16D250","nombre":"Villanueva de Guadamejud","nombrePadre":"Taranc\u00f3n"},
	CM16B251: {"codigo":"CM16B251","nombre":"Villanueva de la Jara","nombrePadre":"Motilla del Palancar"},
	CM16D253: {"codigo":"CM16D253","nombre":"Villar de Ca\u00f1as","nombrePadre":"Taranc\u00f3n"},
	CM16A254: {"codigo":"CM16A254","nombre":"Villar de Domingo Garc\u00eda","nombrePadre":"Cuenca"},
	CM16D255: {"codigo":"CM16D255","nombre":"Villar de la Encina","nombrePadre":"Taranc\u00f3n"},
	CM16A263: {"codigo":"CM16A263","nombre":"Villar de Olalla","nombrePadre":"Cuenca"},
	CM16A258: {"codigo":"CM16A258","nombre":"Villar del Humo","nombrePadre":"Cuenca"},
	CM16A259: {"codigo":"CM16A259","nombre":"Villar del Infantado","nombrePadre":"Cuenca"},
	CM16A910: {"codigo":"CM16A910","nombre":"Villar y Velasco","nombrePadre":"Cuenca"},
	CM16D264: {"codigo":"CM16D264","nombre":"Villarejo de Fuentes","nombrePadre":"Taranc\u00f3n"},
	CM16A265: {"codigo":"CM16A265","nombre":"Villarejo de la Pe\u00f1uela","nombrePadre":"Cuenca"},
	CM16A266: {"codigo":"CM16A266","nombre":"Villarejo-Periesteban","nombrePadre":"Cuenca"},
	CM16D269: {"codigo":"CM16D269","nombre":"Villares del Saz","nombrePadre":"Taranc\u00f3n"},
	CM16D270: {"codigo":"CM16D270","nombre":"Villarrubio","nombrePadre":"Taranc\u00f3n"},
	CM16B271: {"codigo":"CM16B271","nombre":"Villarta","nombrePadre":"Motilla del Palancar"},
	CM16A272: {"codigo":"CM16A272","nombre":"Villas de la Ventosa","nombrePadre":"Cuenca"},
	CM16A273: {"codigo":"CM16A273","nombre":"Villaverde y Pasaconsol","nombrePadre":"Cuenca"},
	CM16A274: {"codigo":"CM16A274","nombre":"V\u00edllora","nombrePadre":"Cuenca"},
	CM16A275: {"codigo":"CM16A275","nombre":"Vindel","nombrePadre":"Cuenca"},
	CM16A276: {"codigo":"CM16A276","nombre":"Y\u00e9meda","nombrePadre":"Cuenca"},
	CM16D277: {"codigo":"CM16D277","nombre":"Zafra de Z\u00e1ncara","nombrePadre":"Taranc\u00f3n"},
	CM16A278: {"codigo":"CM16A278","nombre":"Zafrilla","nombrePadre":"Cuenca"},
	CM16D279: {"codigo":"CM16D279","nombre":"Zarza de Tajo","nombrePadre":"Taranc\u00f3n"},
	CM16A280: {"codigo":"CM16A280","nombre":"Zarzuela","nombrePadre":"Cuenca"},
	CM19A001: {"codigo":"CM19A001","nombre":"Ab\u00e1nades","nombrePadre":"Guadalajara"},
	CM19A002: {"codigo":"CM19A002","nombre":"Ablanque","nombrePadre":"Guadalajara"},
	CM19B003: {"codigo":"CM19B003","nombre":"Adobes","nombrePadre":"Molina"},
	CM19A004: {"codigo":"CM19A004","nombre":"Alaminos","nombrePadre":"Guadalajara"},
	CM19A005: {"codigo":"CM19A005","nombre":"Alarilla","nombrePadre":"Guadalajara"},
	CM19A006: {"codigo":"CM19A006","nombre":"Albalate de Zorita","nombrePadre":"Guadalajara"},
	CM19A007: {"codigo":"CM19A007","nombre":"Albares","nombrePadre":"Guadalajara"},
	CM19C008: {"codigo":"CM19C008","nombre":"Albendiego","nombrePadre":"Sig\u00fcenza"},
	CM19A009: {"codigo":"CM19A009","nombre":"Alcocer","nombrePadre":"Guadalajara"},
	CM19C010: {"codigo":"CM19C010","nombre":"Alcolea de las Pe\u00f1as","nombrePadre":"Sig\u00fcenza"},
	CM19C011: {"codigo":"CM19C011","nombre":"Alcolea del Pinar","nombrePadre":"Sig\u00fcenza"},
	CM19B013: {"codigo":"CM19B013","nombre":"Alcoroches","nombrePadre":"Molina"},
	CM19A015: {"codigo":"CM19A015","nombre":"Aldeanueva de Guadalajara","nombrePadre":"Guadalajara"},
	CM19B016: {"codigo":"CM19B016","nombre":"Algar de Mesa","nombrePadre":"Molina"},
	CM19C017: {"codigo":"CM19C017","nombre":"Algora","nombrePadre":"Sig\u00fcenza"},
	CM19A018: {"codigo":"CM19A018","nombre":"Alh\u00f3ndiga","nombrePadre":"Guadalajara"},
	CM19A019: {"codigo":"CM19A019","nombre":"Alique","nombrePadre":"Guadalajara"},
	CM19C020: {"codigo":"CM19C020","nombre":"Almadrones","nombrePadre":"Sig\u00fcenza"},
	CM19A021: {"codigo":"CM19A021","nombre":"Almoguera","nombrePadre":"Guadalajara"},
	CM19A022: {"codigo":"CM19A022","nombre":"Almonacid de Zorita","nombrePadre":"Guadalajara"},
	CM19A023: {"codigo":"CM19A023","nombre":"Aloc\u00e9n","nombrePadre":"Guadalajara"},
	CM19A024: {"codigo":"CM19A024","nombre":"Alovera","nombrePadre":"Guadalajara"},
	CM19B027: {"codigo":"CM19B027","nombre":"Alustante","nombrePadre":"Molina"},
	CM19C031: {"codigo":"CM19C031","nombre":"Ang\u00f3n","nombrePadre":"Sig\u00fcenza"},
	CM19C032: {"codigo":"CM19C032","nombre":"Anguita","nombrePadre":"Sig\u00fcenza"},
	CM19B033: {"codigo":"CM19B033","nombre":"Anquela del Ducado","nombrePadre":"Molina"},
	CM19B034: {"codigo":"CM19B034","nombre":"Anquela del Pedregal","nombrePadre":"Molina"},
	CM19A036: {"codigo":"CM19A036","nombre":"Aranzueque","nombrePadre":"Guadalajara"},
	CM19A037: {"codigo":"CM19A037","nombre":"Arbanc\u00f3n","nombrePadre":"Guadalajara"},
	CM19A038: {"codigo":"CM19A038","nombre":"Arbeteta","nombrePadre":"Guadalajara"},
	CM19A039: {"codigo":"CM19A039","nombre":"Argecilla","nombrePadre":"Guadalajara"},
	CM19A040: {"codigo":"CM19A040","nombre":"Armallones","nombrePadre":"Guadalajara"},
	CM19A041: {"codigo":"CM19A041","nombre":"Armu\u00f1a de Taju\u00f1a","nombrePadre":"Guadalajara"},
	CM19C042: {"codigo":"CM19C042","nombre":"Arroyo de las Fraguas","nombrePadre":"Sig\u00fcenza"},
	CM19A043: {"codigo":"CM19A043","nombre":"Atanz\u00f3n","nombrePadre":"Guadalajara"},
	CM19C044: {"codigo":"CM19C044","nombre":"Atienza","nombrePadre":"Sig\u00fcenza"},
	CM19A045: {"codigo":"CM19A045","nombre":"Au\u00f1\u00f3n","nombrePadre":"Guadalajara"},
	CM19A046: {"codigo":"CM19A046","nombre":"Azuqueca de Henares","nombrePadre":"Guadalajara"},
	CM19C047: {"codigo":"CM19C047","nombre":"Baides","nombrePadre":"Sig\u00fcenza"},
	CM19B048: {"codigo":"CM19B048","nombre":"Ba\u00f1os de Tajo","nombrePadre":"Molina"},
	CM19C049: {"codigo":"CM19C049","nombre":"Ba\u00f1uelos","nombrePadre":"Sig\u00fcenza"},
	CM19A050: {"codigo":"CM19A050","nombre":"Barriopedro","nombrePadre":"Guadalajara"},
	CM19A051: {"codigo":"CM19A051","nombre":"Berninches","nombrePadre":"Guadalajara"},
	CM19C052: {"codigo":"CM19C052","nombre":"Bodera, La","nombrePadre":"Sig\u00fcenza"},
	CM19A053: {"codigo":"CM19A053","nombre":"Brihuega","nombrePadre":"Guadalajara"},
	CM19A054: {"codigo":"CM19A054","nombre":"Budia","nombrePadre":"Guadalajara"},
	CM19C055: {"codigo":"CM19C055","nombre":"Bujalaro","nombrePadre":"Sig\u00fcenza"},
	CM19C057: {"codigo":"CM19C057","nombre":"Bustares","nombrePadre":"Sig\u00fcenza"},
	CM19A058: {"codigo":"CM19A058","nombre":"Cabanillas del Campo","nombrePadre":"Guadalajara"},
	CM19B059: {"codigo":"CM19B059","nombre":"Campillo de Due\u00f1as","nombrePadre":"Molina"},
	CM19A060: {"codigo":"CM19A060","nombre":"Campillo de Ranas","nombrePadre":"Guadalajara"},
	CM19C061: {"codigo":"CM19C061","nombre":"Campis\u00e1balos","nombrePadre":"Sig\u00fcenza"},
	CM19A064: {"codigo":"CM19A064","nombre":"Canredondo","nombrePadre":"Guadalajara"},
	CM19C065: {"codigo":"CM19C065","nombre":"Cantalojas","nombrePadre":"Sig\u00fcenza"},
	CM19A066: {"codigo":"CM19A066","nombre":"Ca\u00f1izar","nombrePadre":"Guadalajara"},
	CM19A067: {"codigo":"CM19A067","nombre":"Cardoso de la Sierra, El","nombrePadre":"Guadalajara"},
	CM19A070: {"codigo":"CM19A070","nombre":"Casa de Uceda","nombrePadre":"Guadalajara"},
	CM19A071: {"codigo":"CM19A071","nombre":"Casar, El","nombrePadre":"Guadalajara"},
	CM19A073: {"codigo":"CM19A073","nombre":"Casas de San Galindo","nombrePadre":"Guadalajara"},
	CM19A074: {"codigo":"CM19A074","nombre":"Caspue\u00f1as","nombrePadre":"Guadalajara"},
	CM19C075: {"codigo":"CM19C075","nombre":"Castej\u00f3n de Henares","nombrePadre":"Sig\u00fcenza"},
	CM19B076: {"codigo":"CM19B076","nombre":"Castellar de la Muela","nombrePadre":"Molina"},
	CM19A078: {"codigo":"CM19A078","nombre":"Castilforte","nombrePadre":"Guadalajara"},
	CM19B079: {"codigo":"CM19B079","nombre":"Castilnuevo","nombrePadre":"Molina"},
	CM19C080: {"codigo":"CM19C080","nombre":"Cendejas de Enmedio","nombrePadre":"Sig\u00fcenza"},
	CM19C081: {"codigo":"CM19C081","nombre":"Cendejas de la Torre","nombrePadre":"Sig\u00fcenza"},
	CM19A082: {"codigo":"CM19A082","nombre":"Centenera","nombrePadre":"Guadalajara"},
	CM19B103: {"codigo":"CM19B103","nombre":"Checa","nombrePadre":"Molina"},
	CM19B104: {"codigo":"CM19B104","nombre":"Chequilla","nombrePadre":"Molina"},
	CM19A106: {"codigo":"CM19A106","nombre":"Chillar\u00f3n del Rey","nombrePadre":"Guadalajara"},
	CM19A105: {"codigo":"CM19A105","nombre":"Chiloeches","nombrePadre":"Guadalajara"},
	CM19A086: {"codigo":"CM19A086","nombre":"Cifuentes","nombrePadre":"Guadalajara"},
	CM19C087: {"codigo":"CM19C087","nombre":"Cincovillas","nombrePadre":"Sig\u00fcenza"},
	CM19A088: {"codigo":"CM19A088","nombre":"Ciruelas","nombrePadre":"Guadalajara"},
	CM19B089: {"codigo":"CM19B089","nombre":"Ciruelos del Pinar","nombrePadre":"Molina"},
	CM19B090: {"codigo":"CM19B090","nombre":"Cobeta","nombrePadre":"Molina"},
	CM19A091: {"codigo":"CM19A091","nombre":"Cogollor","nombrePadre":"Guadalajara"},
	CM19A092: {"codigo":"CM19A092","nombre":"Cogolludo","nombrePadre":"Guadalajara"},
	CM19C095: {"codigo":"CM19C095","nombre":"Condemios de Abajo","nombrePadre":"Sig\u00fcenza"},
	CM19C096: {"codigo":"CM19C096","nombre":"Condemios de Arriba","nombrePadre":"Sig\u00fcenza"},
	CM19C097: {"codigo":"CM19C097","nombre":"Congostrina","nombrePadre":"Sig\u00fcenza"},
	CM19A098: {"codigo":"CM19A098","nombre":"Copernal","nombrePadre":"Guadalajara"},
	CM19B099: {"codigo":"CM19B099","nombre":"Corduente","nombrePadre":"Molina"},
	CM19A102: {"codigo":"CM19A102","nombre":"Cubillo de Uceda, El","nombrePadre":"Guadalajara"},
	CM19A107: {"codigo":"CM19A107","nombre":"Driebes","nombrePadre":"Guadalajara"},
	CM19A108: {"codigo":"CM19A108","nombre":"Dur\u00f3n","nombrePadre":"Guadalajara"},
	CM19B109: {"codigo":"CM19B109","nombre":"Embid","nombrePadre":"Molina"},
	CM19A110: {"codigo":"CM19A110","nombre":"Escamilla","nombrePadre":"Guadalajara"},
	CM19A111: {"codigo":"CM19A111","nombre":"Escariche","nombrePadre":"Guadalajara"},
	CM19A112: {"codigo":"CM19A112","nombre":"Escopete","nombrePadre":"Guadalajara"},
	CM19A113: {"codigo":"CM19A113","nombre":"Espinosa de Henares","nombrePadre":"Guadalajara"},
	CM19A114: {"codigo":"CM19A114","nombre":"Esplegares","nombrePadre":"Guadalajara"},
	CM19B115: {"codigo":"CM19B115","nombre":"Establ\u00e9s","nombrePadre":"Molina"},
	CM19C116: {"codigo":"CM19C116","nombre":"Estri\u00e9gana","nombrePadre":"Sig\u00fcenza"},
	CM19A117: {"codigo":"CM19A117","nombre":"Fontanar","nombrePadre":"Guadalajara"},
	CM19B118: {"codigo":"CM19B118","nombre":"Fuembellida","nombrePadre":"Molina"},
	CM19A119: {"codigo":"CM19A119","nombre":"Fuencemill\u00e1n","nombrePadre":"Guadalajara"},
	CM19A120: {"codigo":"CM19A120","nombre":"Fuentelahiguera de Albatages","nombrePadre":"Guadalajara"},
	CM19A121: {"codigo":"CM19A121","nombre":"Fuentelencina","nombrePadre":"Guadalajara"},
	CM19B122: {"codigo":"CM19B122","nombre":"Fuentelsaz","nombrePadre":"Molina"},
	CM19A123: {"codigo":"CM19A123","nombre":"Fuentelviejo","nombrePadre":"Guadalajara"},
	CM19A124: {"codigo":"CM19A124","nombre":"Fuentenovilla","nombrePadre":"Guadalajara"},
	CM19A125: {"codigo":"CM19A125","nombre":"Gajanejos","nombrePadre":"Guadalajara"},
	CM19A126: {"codigo":"CM19A126","nombre":"Gal\u00e1pagos","nombrePadre":"Guadalajara"},
	CM19C127: {"codigo":"CM19C127","nombre":"Galve de Sorbe","nombrePadre":"Sig\u00fcenza"},
	CM19C129: {"codigo":"CM19C129","nombre":"Gascue\u00f1a de Bornova","nombrePadre":"Sig\u00fcenza"},
	CM19A130: {"codigo":"CM19A130","nombre":"Guadalajara","nombrePadre":"Guadalajara"},
	CM19A132: {"codigo":"CM19A132","nombre":"Henche","nombrePadre":"Guadalajara"},
	CM19A133: {"codigo":"CM19A133","nombre":"Heras de Ayuso","nombrePadre":"Guadalajara"},
	CM19B134: {"codigo":"CM19B134","nombre":"Herrer\u00eda","nombrePadre":"Molina"},
	CM19C135: {"codigo":"CM19C135","nombre":"Hiendelaencina","nombrePadre":"Sig\u00fcenza"},
	CM19C136: {"codigo":"CM19C136","nombre":"Hijes","nombrePadre":"Sig\u00fcenza"},
	CM19A138: {"codigo":"CM19A138","nombre":"Hita","nombrePadre":"Guadalajara"},
	CM19B139: {"codigo":"CM19B139","nombre":"Hombrados","nombrePadre":"Molina"},
	CM19A142: {"codigo":"CM19A142","nombre":"Hontoba","nombrePadre":"Guadalajara"},
	CM19A143: {"codigo":"CM19A143","nombre":"Horche","nombrePadre":"Guadalajara"},
	CM19C145: {"codigo":"CM19C145","nombre":"Hortezuela de Oc\u00e9n","nombrePadre":"Sig\u00fcenza"},
	CM19C146: {"codigo":"CM19C146","nombre":"Huerce, La","nombrePadre":"Sig\u00fcenza"},
	CM19C147: {"codigo":"CM19C147","nombre":"Hu\u00e9rmeces del Cerro","nombrePadre":"Sig\u00fcenza"},
	CM19A148: {"codigo":"CM19A148","nombre":"Huertahernando","nombrePadre":"Guadalajara"},
	CM19A150: {"codigo":"CM19A150","nombre":"Hueva","nombrePadre":"Guadalajara"},
	CM19A151: {"codigo":"CM19A151","nombre":"Humanes","nombrePadre":"Guadalajara"},
	CM19A152: {"codigo":"CM19A152","nombre":"Illana","nombrePadre":"Guadalajara"},
	CM19C153: {"codigo":"CM19C153","nombre":"Ini\u00e9stola","nombrePadre":"Sig\u00fcenza"},
	CM19A154: {"codigo":"CM19A154","nombre":"Inviernas, Las","nombrePadre":"Guadalajara"},
	CM19A155: {"codigo":"CM19A155","nombre":"Irueste","nombrePadre":"Guadalajara"},
	CM19C156: {"codigo":"CM19C156","nombre":"Jadraque","nombrePadre":"Sig\u00fcenza"},
	CM19C157: {"codigo":"CM19C157","nombre":"Jirueque","nombrePadre":"Sig\u00fcenza"},
	CM19A159: {"codigo":"CM19A159","nombre":"Ledanca","nombrePadre":"Guadalajara"},
	CM19A160: {"codigo":"CM19A160","nombre":"Loranca de Taju\u00f1a","nombrePadre":"Guadalajara"},
	CM19A161: {"codigo":"CM19A161","nombre":"Lupiana","nombrePadre":"Guadalajara"},
	CM19C162: {"codigo":"CM19C162","nombre":"Luzaga","nombrePadre":"Sig\u00fcenza"},
	CM19B163: {"codigo":"CM19B163","nombre":"Luz\u00f3n","nombrePadre":"Molina"},
	CM19A165: {"codigo":"CM19A165","nombre":"Majaelrayo","nombrePadre":"Guadalajara"},
	CM19A166: {"codigo":"CM19A166","nombre":"M\u00e1laga del Fresno","nombrePadre":"Guadalajara"},
	CM19A167: {"codigo":"CM19A167","nombre":"Malaguilla","nombrePadre":"Guadalajara"},
	CM19C168: {"codigo":"CM19C168","nombre":"Mandayona","nombrePadre":"Sig\u00fcenza"},
	CM19A169: {"codigo":"CM19A169","nombre":"Mantiel","nombrePadre":"Guadalajara"},
	CM19B170: {"codigo":"CM19B170","nombre":"Maranch\u00f3n","nombrePadre":"Molina"},
	CM19A171: {"codigo":"CM19A171","nombre":"Marchamalo","nombrePadre":"Guadalajara"},
	CM19A172: {"codigo":"CM19A172","nombre":"Masegoso de Taju\u00f1a","nombrePadre":"Guadalajara"},
	CM19A173: {"codigo":"CM19A173","nombre":"Matarrubia","nombrePadre":"Guadalajara"},
	CM19C174: {"codigo":"CM19C174","nombre":"Matillas","nombrePadre":"Sig\u00fcenza"},
	CM19B175: {"codigo":"CM19B175","nombre":"Mazarete","nombrePadre":"Molina"},
	CM19A176: {"codigo":"CM19A176","nombre":"Mazuecos","nombrePadre":"Guadalajara"},
	CM19C177: {"codigo":"CM19C177","nombre":"Medranda","nombrePadre":"Sig\u00fcenza"},
	CM19B178: {"codigo":"CM19B178","nombre":"Megina","nombrePadre":"Molina"},
	CM19A179: {"codigo":"CM19A179","nombre":"Membrillera","nombrePadre":"Guadalajara"},
	CM19C181: {"codigo":"CM19C181","nombre":"Miedes de Atienza","nombrePadre":"Sig\u00fcenza"},
	CM19A182: {"codigo":"CM19A182","nombre":"Mierla, La","nombrePadre":"Guadalajara"},
	CM19A184: {"codigo":"CM19A184","nombre":"Millana","nombrePadre":"Guadalajara"},
	CM19B183: {"codigo":"CM19B183","nombre":"Milmarcos","nombrePadre":"Molina"},
	CM19C185: {"codigo":"CM19C185","nombre":"Mi\u00f1osa, La","nombrePadre":"Sig\u00fcenza"},
	CM19C186: {"codigo":"CM19C186","nombre":"Mirabueno","nombrePadre":"Sig\u00fcenza"},
	CM19A187: {"codigo":"CM19A187","nombre":"Miralr\u00edo","nombrePadre":"Guadalajara"},
	CM19B188: {"codigo":"CM19B188","nombre":"Mochales","nombrePadre":"Molina"},
	CM19A189: {"codigo":"CM19A189","nombre":"Mohernando","nombrePadre":"Guadalajara"},
	CM19B190: {"codigo":"CM19B190","nombre":"Molina de Arag\u00f3n","nombrePadre":"Molina"},
	CM19A191: {"codigo":"CM19A191","nombre":"Monasterio","nombrePadre":"Guadalajara"},
	CM19A192: {"codigo":"CM19A192","nombre":"Mond\u00e9jar","nombrePadre":"Guadalajara"},
	CM19A193: {"codigo":"CM19A193","nombre":"Montarr\u00f3n","nombrePadre":"Guadalajara"},
	CM19A194: {"codigo":"CM19A194","nombre":"Moratilla de los Meleros","nombrePadre":"Guadalajara"},
	CM19B195: {"codigo":"CM19B195","nombre":"Morenilla","nombrePadre":"Molina"},
	CM19A196: {"codigo":"CM19A196","nombre":"Muduex","nombrePadre":"Guadalajara"},
	CM19C197: {"codigo":"CM19C197","nombre":"Navas de Jadraque, Las","nombrePadre":"Sig\u00fcenza"},
	CM19C198: {"codigo":"CM19C198","nombre":"Negredo","nombrePadre":"Sig\u00fcenza"},
	CM19A199: {"codigo":"CM19A199","nombre":"Ocentejo","nombrePadre":"Guadalajara"},
	CM19A200: {"codigo":"CM19A200","nombre":"Olivar, El","nombrePadre":"Guadalajara"},
	CM19B201: {"codigo":"CM19B201","nombre":"Olmeda de Cobeta","nombrePadre":"Molina"},
	CM19C202: {"codigo":"CM19C202","nombre":"Olmeda de Jadraque, La","nombrePadre":"Sig\u00fcenza"},
	CM19C203: {"codigo":"CM19C203","nombre":"Ordial, El","nombrePadre":"Sig\u00fcenza"},
	CM19B204: {"codigo":"CM19B204","nombre":"Orea","nombrePadre":"Molina"},
	CM19C208: {"codigo":"CM19C208","nombre":"P\u00e1lmaces de Jadraque","nombrePadre":"Sig\u00fcenza"},
	CM19B209: {"codigo":"CM19B209","nombre":"Pardos","nombrePadre":"Molina"},
	CM19C210: {"codigo":"CM19C210","nombre":"Paredes de Sig\u00fcenza","nombrePadre":"Sig\u00fcenza"},
	CM19A211: {"codigo":"CM19A211","nombre":"Pareja","nombrePadre":"Guadalajara"},
	CM19A212: {"codigo":"CM19A212","nombre":"Pastrana","nombrePadre":"Guadalajara"},
	CM19B213: {"codigo":"CM19B213","nombre":"Pedregal, El","nombrePadre":"Molina"},
	CM19B214: {"codigo":"CM19B214","nombre":"Pe\u00f1al\u00e9n","nombrePadre":"Molina"},
	CM19A215: {"codigo":"CM19A215","nombre":"Pe\u00f1alver","nombrePadre":"Guadalajara"},
	CM19B216: {"codigo":"CM19B216","nombre":"Peralejos de las Truchas","nombrePadre":"Molina"},
	CM19A217: {"codigo":"CM19A217","nombre":"Peralveche","nombrePadre":"Guadalajara"},
	CM19C218: {"codigo":"CM19C218","nombre":"Pinilla de Jadraque","nombrePadre":"Sig\u00fcenza"},
	CM19B219: {"codigo":"CM19B219","nombre":"Pinilla de Molina","nombrePadre":"Molina"},
	CM19A220: {"codigo":"CM19A220","nombre":"Pioz","nombrePadre":"Guadalajara"},
	CM19B221: {"codigo":"CM19B221","nombre":"Piqueras","nombrePadre":"Molina"},
	CM19B222: {"codigo":"CM19B222","nombre":"Pobo de Due\u00f1as, El","nombrePadre":"Molina"},
	CM19B223: {"codigo":"CM19B223","nombre":"Poveda de la Sierra","nombrePadre":"Molina"},
	CM19A224: {"codigo":"CM19A224","nombre":"Pozo de Almoguera","nombrePadre":"Guadalajara"},
	CM19A225: {"codigo":"CM19A225","nombre":"Pozo de Guadalajara","nombrePadre":"Guadalajara"},
	CM19C226: {"codigo":"CM19C226","nombre":"Pr\u00e1dena de Atienza","nombrePadre":"Sig\u00fcenza"},
	CM19B227: {"codigo":"CM19B227","nombre":"Prados Redondos","nombrePadre":"Molina"},
	CM19A228: {"codigo":"CM19A228","nombre":"Puebla de Bele\u00f1a","nombrePadre":"Guadalajara"},
	CM19A229: {"codigo":"CM19A229","nombre":"Puebla de Valles","nombrePadre":"Guadalajara"},
	CM19A230: {"codigo":"CM19A230","nombre":"Quer","nombrePadre":"Guadalajara"},
	CM19C231: {"codigo":"CM19C231","nombre":"Rebollosa de Jadraque","nombrePadre":"Sig\u00fcenza"},
	CM19A232: {"codigo":"CM19A232","nombre":"Recuenco, El","nombrePadre":"Guadalajara"},
	CM19A233: {"codigo":"CM19A233","nombre":"Renera","nombrePadre":"Guadalajara"},
	CM19A234: {"codigo":"CM19A234","nombre":"Retiendas","nombrePadre":"Guadalajara"},
	CM19A235: {"codigo":"CM19A235","nombre":"Riba de Saelices","nombrePadre":"Guadalajara"},
	CM19B237: {"codigo":"CM19B237","nombre":"Rillo de Gallo","nombrePadre":"Molina"},
	CM19C238: {"codigo":"CM19C238","nombre":"Riofr\u00edo del Llano","nombrePadre":"Sig\u00fcenza"},
	CM19A239: {"codigo":"CM19A239","nombre":"Robledillo de Mohernando","nombrePadre":"Guadalajara"},
	CM19C240: {"codigo":"CM19C240","nombre":"Robledo de Corpes","nombrePadre":"Sig\u00fcenza"},
	CM19C241: {"codigo":"CM19C241","nombre":"Romanillos de Atienza","nombrePadre":"Sig\u00fcenza"},
	CM19A242: {"codigo":"CM19A242","nombre":"Romanones","nombrePadre":"Guadalajara"},
	CM19B243: {"codigo":"CM19B243","nombre":"Rueda de la Sierra","nombrePadre":"Molina"},
	CM19A244: {"codigo":"CM19A244","nombre":"Sacecorbo","nombrePadre":"Guadalajara"},
	CM19A245: {"codigo":"CM19A245","nombre":"Saced\u00f3n","nombrePadre":"Guadalajara"},
	CM19A246: {"codigo":"CM19A246","nombre":"Saelices de la Sal","nombrePadre":"Guadalajara"},
	CM19A247: {"codigo":"CM19A247","nombre":"Salmer\u00f3n","nombrePadre":"Guadalajara"},
	CM19C248: {"codigo":"CM19C248","nombre":"San Andr\u00e9s del Congosto","nombrePadre":"Sig\u00fcenza"},
	CM19A249: {"codigo":"CM19A249","nombre":"San Andr\u00e9s del Rey","nombrePadre":"Guadalajara"},
	CM19C250: {"codigo":"CM19C250","nombre":"Santiuste","nombrePadre":"Sig\u00fcenza"},
	CM19C251: {"codigo":"CM19C251","nombre":"Sa\u00faca","nombrePadre":"Sig\u00fcenza"},
	CM19A252: {"codigo":"CM19A252","nombre":"Sayat\u00f3n","nombrePadre":"Guadalajara"},
	CM19B254: {"codigo":"CM19B254","nombre":"Selas","nombrePadre":"Molina"},
	CM19C901: {"codigo":"CM19C901","nombre":"Semillas","nombrePadre":"Sig\u00fcenza"},
	CM19B255: {"codigo":"CM19B255","nombre":"Setiles","nombrePadre":"Molina"},
	CM19C256: {"codigo":"CM19C256","nombre":"Sienes","nombrePadre":"Sig\u00fcenza"},
	CM19C257: {"codigo":"CM19C257","nombre":"Sig\u00fcenza","nombrePadre":"Sig\u00fcenza"},
	CM19A258: {"codigo":"CM19A258","nombre":"Solanillos del Extremo","nombrePadre":"Guadalajara"},
	CM19C259: {"codigo":"CM19C259","nombre":"Somolinos","nombrePadre":"Sig\u00fcenza"},
	CM19A260: {"codigo":"CM19A260","nombre":"Sotillo, El","nombrePadre":"Guadalajara"},
	CM19A261: {"codigo":"CM19A261","nombre":"Sotodosos","nombrePadre":"Guadalajara"},
	CM19A262: {"codigo":"CM19A262","nombre":"Tamaj\u00f3n","nombrePadre":"Guadalajara"},
	CM19A263: {"codigo":"CM19A263","nombre":"Taragudo","nombrePadre":"Guadalajara"},
	CM19B264: {"codigo":"CM19B264","nombre":"Taravilla","nombrePadre":"Molina"},
	CM19B265: {"codigo":"CM19B265","nombre":"Tartanedo","nombrePadre":"Molina"},
	CM19A266: {"codigo":"CM19A266","nombre":"Tendilla","nombrePadre":"Guadalajara"},
	CM19B267: {"codigo":"CM19B267","nombre":"Terzaga","nombrePadre":"Molina"},
	CM19B268: {"codigo":"CM19B268","nombre":"Tierzo","nombrePadre":"Molina"},
	CM19C269: {"codigo":"CM19C269","nombre":"Toba, La","nombrePadre":"Sig\u00fcenza"},
	CM19B271: {"codigo":"CM19B271","nombre":"Tordellego","nombrePadre":"Molina"},
	CM19C270: {"codigo":"CM19C270","nombre":"Tordelr\u00e1bano","nombrePadre":"Sig\u00fcenza"},
	CM19B272: {"codigo":"CM19B272","nombre":"Tordesilos","nombrePadre":"Molina"},
	CM19A274: {"codigo":"CM19A274","nombre":"Torija","nombrePadre":"Guadalajara"},
	CM19A279: {"codigo":"CM19A279","nombre":"Torre del Burgo","nombrePadre":"Guadalajara"},
	CM19B277: {"codigo":"CM19B277","nombre":"Torrecuadrada de Molina","nombrePadre":"Molina"},
	CM19A278: {"codigo":"CM19A278","nombre":"Torrecuadradilla","nombrePadre":"Guadalajara"},
	CM19A280: {"codigo":"CM19A280","nombre":"Torrej\u00f3n del Rey","nombrePadre":"Guadalajara"},
	CM19C281: {"codigo":"CM19C281","nombre":"Torremocha de Jadraque","nombrePadre":"Sig\u00fcenza"},
	CM19C282: {"codigo":"CM19C282","nombre":"Torremocha del Campo","nombrePadre":"Sig\u00fcenza"},
	CM19B283: {"codigo":"CM19B283","nombre":"Torremocha del Pinar","nombrePadre":"Molina"},
	CM19B284: {"codigo":"CM19B284","nombre":"Torremochuela","nombrePadre":"Molina"},
	CM19B285: {"codigo":"CM19B285","nombre":"Torrubia","nombrePadre":"Molina"},
	CM19A286: {"codigo":"CM19A286","nombre":"T\u00f3rtola de Henares","nombrePadre":"Guadalajara"},
	CM19B287: {"codigo":"CM19B287","nombre":"Tortuera","nombrePadre":"Molina"},
	CM19A288: {"codigo":"CM19A288","nombre":"Tortuero","nombrePadre":"Guadalajara"},
	CM19B289: {"codigo":"CM19B289","nombre":"Tra\u00edd","nombrePadre":"Molina"},
	CM19A290: {"codigo":"CM19A290","nombre":"Trijueque","nombrePadre":"Guadalajara"},
	CM19A291: {"codigo":"CM19A291","nombre":"Trillo","nombrePadre":"Guadalajara"},
	CM19A293: {"codigo":"CM19A293","nombre":"Uceda","nombrePadre":"Guadalajara"},
	CM19C294: {"codigo":"CM19C294","nombre":"Ujados","nombrePadre":"Sig\u00fcenza"},
	CM19A296: {"codigo":"CM19A296","nombre":"Utande","nombrePadre":"Guadalajara"},
	CM19A297: {"codigo":"CM19A297","nombre":"Valdarachas","nombrePadre":"Guadalajara"},
	CM19A298: {"codigo":"CM19A298","nombre":"Valdearenas","nombrePadre":"Guadalajara"},
	CM19A299: {"codigo":"CM19A299","nombre":"Valdeavellano","nombrePadre":"Guadalajara"},
	CM19A300: {"codigo":"CM19A300","nombre":"Valdeaveruelo","nombrePadre":"Guadalajara"},
	CM19A301: {"codigo":"CM19A301","nombre":"Valdeconcha","nombrePadre":"Guadalajara"},
	CM19A302: {"codigo":"CM19A302","nombre":"Valdegrudas","nombrePadre":"Guadalajara"},
	CM19C303: {"codigo":"CM19C303","nombre":"Valdelcubo","nombrePadre":"Sig\u00fcenza"},
	CM19A304: {"codigo":"CM19A304","nombre":"Valdenu\u00f1o Fern\u00e1ndez","nombrePadre":"Guadalajara"},
	CM19A305: {"codigo":"CM19A305","nombre":"Valdepe\u00f1as de la Sierra","nombrePadre":"Guadalajara"},
	CM19A306: {"codigo":"CM19A306","nombre":"Valderrebollo","nombrePadre":"Guadalajara"},
	CM19A307: {"codigo":"CM19A307","nombre":"Valdesotos","nombrePadre":"Guadalajara"},
	CM19A308: {"codigo":"CM19A308","nombre":"Valfermoso de Taju\u00f1a","nombrePadre":"Guadalajara"},
	CM19B309: {"codigo":"CM19B309","nombre":"Valhermoso","nombrePadre":"Molina"},
	CM19A310: {"codigo":"CM19A310","nombre":"Valtablado del R\u00edo","nombrePadre":"Guadalajara"},
	CM19C311: {"codigo":"CM19C311","nombre":"Valverde de los Arroyos","nombrePadre":"Sig\u00fcenza"},
	CM19C314: {"codigo":"CM19C314","nombre":"Viana de Jadraque","nombrePadre":"Sig\u00fcenza"},
	CM19A317: {"codigo":"CM19A317","nombre":"Villanueva de Alcor\u00f3n","nombrePadre":"Guadalajara"},
	CM19A318: {"codigo":"CM19A318","nombre":"Villanueva de Argecilla","nombrePadre":"Guadalajara"},
	CM19A319: {"codigo":"CM19A319","nombre":"Villanueva de la Torre","nombrePadre":"Guadalajara"},
	CM19C321: {"codigo":"CM19C321","nombre":"Villares de Jadraque","nombrePadre":"Sig\u00fcenza"},
	CM19C322: {"codigo":"CM19C322","nombre":"Villaseca de Henares","nombrePadre":"Sig\u00fcenza"},
	CM19A323: {"codigo":"CM19A323","nombre":"Villaseca de Uceda","nombrePadre":"Guadalajara"},
	CM19B324: {"codigo":"CM19B324","nombre":"Villel de Mesa","nombrePadre":"Molina"},
	CM19A325: {"codigo":"CM19A325","nombre":"Vi\u00f1uelas","nombrePadre":"Guadalajara"},
	CM19A326: {"codigo":"CM19A326","nombre":"Yebes","nombrePadre":"Guadalajara"},
	CM19A327: {"codigo":"CM19A327","nombre":"Yebra","nombrePadre":"Guadalajara"},
	CM19A329: {"codigo":"CM19A329","nombre":"Y\u00e9lamos de Abajo","nombrePadre":"Guadalajara"},
	CM19A330: {"codigo":"CM19A330","nombre":"Y\u00e9lamos de Arriba","nombrePadre":"Guadalajara"},
	CM19A331: {"codigo":"CM19A331","nombre":"Yunquera de Henares","nombrePadre":"Guadalajara"},
	CM19B332: {"codigo":"CM19B332","nombre":"Yunta, La","nombrePadre":"Molina"},
	CM19A333: {"codigo":"CM19A333","nombre":"Zaorejas","nombrePadre":"Guadalajara"},
	CM19C334: {"codigo":"CM19C334","nombre":"Zarzuela de Jadraque","nombrePadre":"Sig\u00fcenza"},
	CM19A335: {"codigo":"CM19A335","nombre":"Zorita de los Canes","nombrePadre":"Guadalajara"},
	CM45B001: {"codigo":"CM45B001","nombre":"Ajofr\u00edn","nombrePadre":"Orgaz"},
	CM45E002: {"codigo":"CM45E002","nombre":"Alameda de la Sagra","nombrePadre":"Toledo"},
	CM45F003: {"codigo":"CM45F003","nombre":"Albarreal de Tajo","nombrePadre":"Torrijos"},
	CM45F004: {"codigo":"CM45F004","nombre":"Alcab\u00f3n","nombrePadre":"Torrijos"},
	CM45D005: {"codigo":"CM45D005","nombre":"Alca\u00f1izo","nombrePadre":"Talavera de la Reina"},
	CM45D006: {"codigo":"CM45D006","nombre":"Alcaudete de la Jara","nombrePadre":"Talavera de la Reina"},
	CM45D007: {"codigo":"CM45D007","nombre":"Alcolea de Tajo","nombrePadre":"Talavera de la Reina"},
	CM45F008: {"codigo":"CM45F008","nombre":"Aldea en Cabo","nombrePadre":"Torrijos"},
	CM45D009: {"codigo":"CM45D009","nombre":"Aldeanueva de Barbarroya","nombrePadre":"Talavera de la Reina"},
	CM45D010: {"codigo":"CM45D010","nombre":"Aldeanueva de San Bartolom\u00e9","nombrePadre":"Talavera de la Reina"},
	CM45D011: {"codigo":"CM45D011","nombre":"Almendral de la Ca\u00f1ada","nombrePadre":"Talavera de la Reina"},
	CM45B012: {"codigo":"CM45B012","nombre":"Almonacid de Toledo","nombrePadre":"Orgaz"},
	CM45F013: {"codigo":"CM45F013","nombre":"Almorox","nombrePadre":"Torrijos"},
	CM45E014: {"codigo":"CM45E014","nombre":"A\u00f1over de Tajo","nombrePadre":"Toledo"},
	CM45F015: {"codigo":"CM45F015","nombre":"Arcic\u00f3llar","nombrePadre":"Torrijos"},
	CM45E016: {"codigo":"CM45E016","nombre":"Arg\u00e9s","nombrePadre":"Toledo"},
	CM45D017: {"codigo":"CM45D017","nombre":"Azut\u00e1n","nombrePadre":"Talavera de la Reina"},
	CM45F018: {"codigo":"CM45F018","nombre":"Barcience","nombrePadre":"Torrijos"},
	CM45E019: {"codigo":"CM45E019","nombre":"Bargas","nombrePadre":"Toledo"},
	CM45D020: {"codigo":"CM45D020","nombre":"Belv\u00eds de la Jara","nombrePadre":"Talavera de la Reina"},
	CM45E021: {"codigo":"CM45E021","nombre":"Borox","nombrePadre":"Toledo"},
	CM45D022: {"codigo":"CM45D022","nombre":"Buenaventura","nombrePadre":"Talavera de la Reina"},
	CM45E023: {"codigo":"CM45E023","nombre":"Burguillos de Toledo","nombrePadre":"Toledo"},
	CM45F024: {"codigo":"CM45F024","nombre":"Buruj\u00f3n","nombrePadre":"Torrijos"},
	CM45E025: {"codigo":"CM45E025","nombre":"Caba\u00f1as de la Sagra","nombrePadre":"Toledo"},
	CM45A026: {"codigo":"CM45A026","nombre":"Caba\u00f1as de Yepes","nombrePadre":"Oca\u00f1a"},
	CM45C027: {"codigo":"CM45C027","nombre":"Cabezamesada","nombrePadre":"Quintanar de la Orden"},
	CM45D028: {"codigo":"CM45D028","nombre":"Calera y Chozas","nombrePadre":"Talavera de la Reina"},
	CM45D029: {"codigo":"CM45D029","nombre":"Caleruela","nombrePadre":"Talavera de la Reina"},
	CM45D030: {"codigo":"CM45D030","nombre":"Calzada de Oropesa","nombrePadre":"Talavera de la Reina"},
	CM45F031: {"codigo":"CM45F031","nombre":"Camarena","nombrePadre":"Torrijos"},
	CM45F032: {"codigo":"CM45F032","nombre":"Camarenilla","nombrePadre":"Torrijos"},
	CM45D033: {"codigo":"CM45D033","nombre":"Campillo de la Jara, El","nombrePadre":"Talavera de la Reina"},
	CM45B034: {"codigo":"CM45B034","nombre":"Camu\u00f1as","nombrePadre":"Orgaz"},
	CM45D035: {"codigo":"CM45D035","nombre":"Cardiel de los Montes","nombrePadre":"Talavera de la Reina"},
	CM45F036: {"codigo":"CM45F036","nombre":"Carmena","nombrePadre":"Torrijos"},
	CM45F037: {"codigo":"CM45F037","nombre":"Carpio de Tajo, El","nombrePadre":"Torrijos"},
	CM45E038: {"codigo":"CM45E038","nombre":"Carranque","nombrePadre":"Toledo"},
	CM45F039: {"codigo":"CM45F039","nombre":"Carriches","nombrePadre":"Torrijos"},
	CM45F040: {"codigo":"CM45F040","nombre":"Casar de Escalona, El","nombrePadre":"Torrijos"},
	CM45E041: {"codigo":"CM45E041","nombre":"Casarrubios del Monte","nombrePadre":"Toledo"},
	CM45E042: {"codigo":"CM45E042","nombre":"Casasbuenas","nombrePadre":"Toledo"},
	CM45D043: {"codigo":"CM45D043","nombre":"Castillo de Bayuela","nombrePadre":"Talavera de la Reina"},
	CM45D045: {"codigo":"CM45D045","nombre":"Cazalegas","nombrePadre":"Talavera de la Reina"},
	CM45D046: {"codigo":"CM45D046","nombre":"Cebolla","nombrePadre":"Talavera de la Reina"},
	CM45E047: {"codigo":"CM45E047","nombre":"Cedillo del Condado","nombrePadre":"Toledo"},
	CM45D048: {"codigo":"CM45D048","nombre":"Cerralbos, Los","nombrePadre":"Talavera de la Reina"},
	CM45D049: {"codigo":"CM45D049","nombre":"Cervera de los Montes","nombrePadre":"Talavera de la Reina"},
	CM45E056: {"codigo":"CM45E056","nombre":"Chozas de Canales","nombrePadre":"Toledo"},
	CM45B057: {"codigo":"CM45B057","nombre":"Chueca","nombrePadre":"Orgaz"},
	CM45A050: {"codigo":"CM45A050","nombre":"Ciruelos","nombrePadre":"Oca\u00f1a"},
	CM45E051: {"codigo":"CM45E051","nombre":"Cobeja","nombrePadre":"Toledo"},
	CM45E052: {"codigo":"CM45E052","nombre":"Cobisa","nombrePadre":"Toledo"},
	CM45B053: {"codigo":"CM45B053","nombre":"Consuegra","nombrePadre":"Orgaz"},
	CM45C054: {"codigo":"CM45C054","nombre":"Corral de Almaguer","nombrePadre":"Quintanar de la Orden"},
	CM45E055: {"codigo":"CM45E055","nombre":"Cuerva","nombrePadre":"Toledo"},
	CM45F058: {"codigo":"CM45F058","nombre":"Domingo P\u00e9rez","nombrePadre":"Torrijos"},
	CM45A059: {"codigo":"CM45A059","nombre":"Dosbarrios","nombrePadre":"Oca\u00f1a"},
	CM45F060: {"codigo":"CM45F060","nombre":"Erustes","nombrePadre":"Torrijos"},
	CM45F061: {"codigo":"CM45F061","nombre":"Escalona","nombrePadre":"Torrijos"},
	CM45F062: {"codigo":"CM45F062","nombre":"Escalonilla","nombrePadre":"Torrijos"},
	CM45D063: {"codigo":"CM45D063","nombre":"Espinoso del Rey","nombrePadre":"Talavera de la Reina"},
	CM45E064: {"codigo":"CM45E064","nombre":"Esquivias","nombrePadre":"Toledo"},
	CM45D065: {"codigo":"CM45D065","nombre":"Estrella, La","nombrePadre":"Talavera de la Reina"},
	CM45F066: {"codigo":"CM45F066","nombre":"Fuensalida","nombrePadre":"Torrijos"},
	CM45E067: {"codigo":"CM45E067","nombre":"G\u00e1lvez","nombrePadre":"Toledo"},
	CM45F068: {"codigo":"CM45F068","nombre":"Garciotum","nombrePadre":"Torrijos"},
	CM45F069: {"codigo":"CM45F069","nombre":"Gerindote","nombrePadre":"Torrijos"},
	CM45E070: {"codigo":"CM45E070","nombre":"Guadamur","nombrePadre":"Toledo"},
	CM45A071: {"codigo":"CM45A071","nombre":"Guardia, La","nombrePadre":"Oca\u00f1a"},
	CM45D072: {"codigo":"CM45D072","nombre":"Herencias, Las","nombrePadre":"Talavera de la Reina"},
	CM45D073: {"codigo":"CM45D073","nombre":"Herreruela de Oropesa","nombrePadre":"Talavera de la Reina"},
	CM45D074: {"codigo":"CM45D074","nombre":"Hinojosa de San Vicente","nombrePadre":"Talavera de la Reina"},
	CM45E075: {"codigo":"CM45E075","nombre":"Hontanar","nombrePadre":"Toledo"},
	CM45F076: {"codigo":"CM45F076","nombre":"Hormigos","nombrePadre":"Torrijos"},
	CM45F077: {"codigo":"CM45F077","nombre":"Huecas","nombrePadre":"Torrijos"},
	CM45A078: {"codigo":"CM45A078","nombre":"Huerta de Valdecar\u00e1banos","nombrePadre":"Oca\u00f1a"},
	CM45D079: {"codigo":"CM45D079","nombre":"Iglesuela del Ti\u00e9tar, La","nombrePadre":"Talavera de la Reina"},
	CM45D080: {"codigo":"CM45D080","nombre":"Ill\u00e1n de Vacas","nombrePadre":"Talavera de la Reina"},
	CM45E081: {"codigo":"CM45E081","nombre":"Illescas","nombrePadre":"Toledo"},
	CM45D082: {"codigo":"CM45D082","nombre":"Lagartera","nombrePadre":"Talavera de la Reina"},
	CM45E083: {"codigo":"CM45E083","nombre":"Layos","nombrePadre":"Toledo"},
	CM45A084: {"codigo":"CM45A084","nombre":"Lillo","nombrePadre":"Oca\u00f1a"},
	CM45E085: {"codigo":"CM45E085","nombre":"Lominchar","nombrePadre":"Toledo"},
	CM45D086: {"codigo":"CM45D086","nombre":"Lucillos","nombrePadre":"Talavera de la Reina"},
	CM45B087: {"codigo":"CM45B087","nombre":"Madridejos","nombrePadre":"Orgaz"},
	CM45E088: {"codigo":"CM45E088","nombre":"Mag\u00e1n","nombrePadre":"Toledo"},
	CM45D089: {"codigo":"CM45D089","nombre":"Malpica de Tajo","nombrePadre":"Talavera de la Reina"},
	CM45B090: {"codigo":"CM45B090","nombre":"Manzaneque","nombrePadre":"Orgaz"},
	CM45F091: {"codigo":"CM45F091","nombre":"Maqueda","nombrePadre":"Torrijos"},
	CM45B092: {"codigo":"CM45B092","nombre":"Marjaliza","nombrePadre":"Orgaz"},
	CM45D093: {"codigo":"CM45D093","nombre":"Marrupe","nombrePadre":"Talavera de la Reina"},
	CM45B094: {"codigo":"CM45B094","nombre":"Mascaraque","nombrePadre":"Orgaz"},
	CM45F095: {"codigo":"CM45F095","nombre":"Mata, La","nombrePadre":"Torrijos"},
	CM45B096: {"codigo":"CM45B096","nombre":"Mazarambroz","nombrePadre":"Orgaz"},
	CM45D097: {"codigo":"CM45D097","nombre":"Mejorada","nombrePadre":"Talavera de la Reina"},
	CM45E098: {"codigo":"CM45E098","nombre":"Menasalbas","nombrePadre":"Toledo"},
	CM45F099: {"codigo":"CM45F099","nombre":"M\u00e9ntrida","nombrePadre":"Torrijos"},
	CM45F100: {"codigo":"CM45F100","nombre":"Mesegar de Tajo","nombrePadre":"Torrijos"},
	CM45C101: {"codigo":"CM45C101","nombre":"Miguel Esteban","nombrePadre":"Quintanar de la Orden"},
	CM45E102: {"codigo":"CM45E102","nombre":"Mocej\u00f3n","nombrePadre":"Toledo"},
	CM45D103: {"codigo":"CM45D103","nombre":"Mohedas de la Jara","nombrePadre":"Talavera de la Reina"},
	CM45D104: {"codigo":"CM45D104","nombre":"Montearag\u00f3n","nombrePadre":"Talavera de la Reina"},
	CM45D105: {"codigo":"CM45D105","nombre":"Montesclaros","nombrePadre":"Talavera de la Reina"},
	CM45B106: {"codigo":"CM45B106","nombre":"Mora","nombrePadre":"Orgaz"},
	CM45E107: {"codigo":"CM45E107","nombre":"Nambroca","nombrePadre":"Toledo"},
	CM45D108: {"codigo":"CM45D108","nombre":"Nava de Ricomalillo, La","nombrePadre":"Talavera de la Reina"},
	CM45E109: {"codigo":"CM45E109","nombre":"Navahermosa","nombrePadre":"Toledo"},
	CM45D110: {"codigo":"CM45D110","nombre":"Navalc\u00e1n","nombrePadre":"Talavera de la Reina"},
	CM45D111: {"codigo":"CM45D111","nombre":"Navalmoralejo","nombrePadre":"Talavera de la Reina"},
	CM45D112: {"codigo":"CM45D112","nombre":"Navalmorales, Los","nombrePadre":"Talavera de la Reina"},
	CM45D113: {"codigo":"CM45D113","nombre":"Navalucillos, Los","nombrePadre":"Talavera de la Reina"},
	CM45D114: {"codigo":"CM45D114","nombre":"Navamorcuende","nombrePadre":"Talavera de la Reina"},
	CM45A115: {"codigo":"CM45A115","nombre":"Noblejas","nombrePadre":"Oca\u00f1a"},
	CM45E116: {"codigo":"CM45E116","nombre":"Noez","nombrePadre":"Toledo"},
	CM45F117: {"codigo":"CM45F117","nombre":"Nombela","nombrePadre":"Torrijos"},
	CM45F118: {"codigo":"CM45F118","nombre":"Nov\u00e9s","nombrePadre":"Torrijos"},
	CM45E119: {"codigo":"CM45E119","nombre":"Numancia de la Sagra","nombrePadre":"Toledo"},
	CM45F120: {"codigo":"CM45F120","nombre":"Nu\u00f1o G\u00f3mez","nombrePadre":"Torrijos"},
	CM45A121: {"codigo":"CM45A121","nombre":"Oca\u00f1a","nombrePadre":"Oca\u00f1a"},
	CM45E122: {"codigo":"CM45E122","nombre":"Ol\u00edas del Rey","nombrePadre":"Toledo"},
	CM45A123: {"codigo":"CM45A123","nombre":"Ont\u00edgola","nombrePadre":"Oca\u00f1a"},
	CM45B124: {"codigo":"CM45B124","nombre":"Orgaz","nombrePadre":"Orgaz"},
	CM45D125: {"codigo":"CM45D125","nombre":"Oropesa","nombrePadre":"Talavera de la Reina"},
	CM45F126: {"codigo":"CM45F126","nombre":"Otero","nombrePadre":"Torrijos"},
	CM45E127: {"codigo":"CM45E127","nombre":"Palomeque","nombrePadre":"Toledo"},
	CM45E128: {"codigo":"CM45E128","nombre":"Pantoja","nombrePadre":"Toledo"},
	CM45F129: {"codigo":"CM45F129","nombre":"Paredes de Escalona","nombrePadre":"Torrijos"},
	CM45D130: {"codigo":"CM45D130","nombre":"Parrillas","nombrePadre":"Talavera de la Reina"},
	CM45F131: {"codigo":"CM45F131","nombre":"Pelahust\u00e1n","nombrePadre":"Torrijos"},
	CM45D132: {"codigo":"CM45D132","nombre":"Pepino","nombrePadre":"Talavera de la Reina"},
	CM45E133: {"codigo":"CM45E133","nombre":"Pol\u00e1n","nombrePadre":"Toledo"},
	CM45F134: {"codigo":"CM45F134","nombre":"Portillo de Toledo","nombrePadre":"Torrijos"},
	CM45C135: {"codigo":"CM45C135","nombre":"Puebla de Almoradiel, La","nombrePadre":"Quintanar de la Orden"},
	CM45F136: {"codigo":"CM45F136","nombre":"Puebla de Montalb\u00e1n, La","nombrePadre":"Torrijos"},
	CM45D137: {"codigo":"CM45D137","nombre":"Pueblanueva, La","nombrePadre":"Talavera de la Reina"},
	CM45D138: {"codigo":"CM45D138","nombre":"Puente del Arzobispo, El","nombrePadre":"Talavera de la Reina"},
	CM45D139: {"codigo":"CM45D139","nombre":"Puerto de San Vicente","nombrePadre":"Talavera de la Reina"},
	CM45E140: {"codigo":"CM45E140","nombre":"Pulgar","nombrePadre":"Toledo"},
	CM45C141: {"codigo":"CM45C141","nombre":"Quero","nombrePadre":"Quintanar de la Orden"},
	CM45C142: {"codigo":"CM45C142","nombre":"Quintanar de la Orden","nombrePadre":"Quintanar de la Orden"},
	CM45F143: {"codigo":"CM45F143","nombre":"Quismondo","nombrePadre":"Torrijos"},
	CM45D144: {"codigo":"CM45D144","nombre":"Real de San Vicente, El","nombrePadre":"Talavera de la Reina"},
	CM45E145: {"codigo":"CM45E145","nombre":"Recas","nombrePadre":"Toledo"},
	CM45D146: {"codigo":"CM45D146","nombre":"Retamoso de la Jara","nombrePadre":"Talavera de la Reina"},
	CM45F147: {"codigo":"CM45F147","nombre":"Rielves","nombrePadre":"Torrijos"},
	CM45D148: {"codigo":"CM45D148","nombre":"Robledo del Mazo","nombrePadre":"Talavera de la Reina"},
	CM45C149: {"codigo":"CM45C149","nombre":"Romeral, El","nombrePadre":"Quintanar de la Orden"},
	CM45D150: {"codigo":"CM45D150","nombre":"San Bartolom\u00e9 de las Abiertas","nombrePadre":"Talavera de la Reina"},
	CM45E151: {"codigo":"CM45E151","nombre":"San Mart\u00edn de Montalb\u00e1n","nombrePadre":"Toledo"},
	CM45D152: {"codigo":"CM45D152","nombre":"San Mart\u00edn de Pusa","nombrePadre":"Talavera de la Reina"},
	CM45E153: {"codigo":"CM45E153","nombre":"San Pablo de los Montes","nombrePadre":"Toledo"},
	CM45D154: {"codigo":"CM45D154","nombre":"San Rom\u00e1n de los Montes","nombrePadre":"Talavera de la Reina"},
	CM45D155: {"codigo":"CM45D155","nombre":"Santa Ana de Pusa","nombrePadre":"Talavera de la Reina"},
	CM45A156: {"codigo":"CM45A156","nombre":"Santa Cruz de la Zarza","nombrePadre":"Oca\u00f1a"},
	CM45F157: {"codigo":"CM45F157","nombre":"Santa Cruz del Retamar","nombrePadre":"Torrijos"},
	CM45F158: {"codigo":"CM45F158","nombre":"Santa Olalla","nombrePadre":"Torrijos"},
	CM45F901: {"codigo":"CM45F901","nombre":"Santo Domingo-Caudilla","nombrePadre":"Torrijos"},
	CM45D159: {"codigo":"CM45D159","nombre":"Sartajada","nombrePadre":"Talavera de la Reina"},
	CM45D160: {"codigo":"CM45D160","nombre":"Segurilla","nombrePadre":"Talavera de la Reina"},
	CM45E161: {"codigo":"CM45E161","nombre":"Sese\u00f1a","nombrePadre":"Toledo"},
	CM45D162: {"codigo":"CM45D162","nombre":"Sevilleja de la Jara","nombrePadre":"Talavera de la Reina"},
	CM45B163: {"codigo":"CM45B163","nombre":"Sonseca","nombrePadre":"Orgaz"},
	CM45D164: {"codigo":"CM45D164","nombre":"Sotillo de las Palomas","nombrePadre":"Talavera de la Reina"},
	CM45D165: {"codigo":"CM45D165","nombre":"Talavera de la Reina","nombrePadre":"Talavera de la Reina"},
	CM45A166: {"codigo":"CM45A166","nombre":"Tembleque","nombrePadre":"Oca\u00f1a"},
	CM45C167: {"codigo":"CM45C167","nombre":"Toboso, El","nombrePadre":"Quintanar de la Orden"},
	CM45E168: {"codigo":"CM45E168","nombre":"Toledo","nombrePadre":"Toledo"},
	CM45D169: {"codigo":"CM45D169","nombre":"Torralba de Oropesa","nombrePadre":"Talavera de la Reina"},
	CM45F171: {"codigo":"CM45F171","nombre":"Torre de Esteban Hambr\u00e1n, La","nombrePadre":"Torrijos"},
	CM45D170: {"codigo":"CM45D170","nombre":"Torrecilla de la Jara","nombrePadre":"Talavera de la Reina"},
	CM45D172: {"codigo":"CM45D172","nombre":"Torrico","nombrePadre":"Talavera de la Reina"},
	CM45F173: {"codigo":"CM45F173","nombre":"Torrijos","nombrePadre":"Torrijos"},
	CM45E174: {"codigo":"CM45E174","nombre":"Totan\u00e9s","nombrePadre":"Toledo"},
	CM45B175: {"codigo":"CM45B175","nombre":"Turleque","nombrePadre":"Orgaz"},
	CM45E176: {"codigo":"CM45E176","nombre":"Ugena","nombrePadre":"Toledo"},
	CM45B177: {"codigo":"CM45B177","nombre":"Urda","nombrePadre":"Orgaz"},
	CM45D179: {"codigo":"CM45D179","nombre":"Valdeverdeja","nombrePadre":"Talavera de la Reina"},
	CM45E180: {"codigo":"CM45E180","nombre":"Valmojado","nombrePadre":"Toledo"},
	CM45D181: {"codigo":"CM45D181","nombre":"Velada","nombrePadre":"Talavera de la Reina"},
	CM45E182: {"codigo":"CM45E182","nombre":"Ventas con Pe\u00f1a Aguilera, Las","nombrePadre":"Toledo"},
	CM45E183: {"codigo":"CM45E183","nombre":"Ventas de Retamosa, Las","nombrePadre":"Toledo"},
	CM45D184: {"codigo":"CM45D184","nombre":"Ventas de San Juli\u00e1n, Las","nombrePadre":"Talavera de la Reina"},
	CM45C186: {"codigo":"CM45C186","nombre":"Villa de Don Fadrique, La","nombrePadre":"Quintanar de la Orden"},
	CM45C185: {"codigo":"CM45C185","nombre":"Villaca\u00f1as","nombrePadre":"Quintanar de la Orden"},
	CM45B187: {"codigo":"CM45B187","nombre":"Villafranca de los Caballeros","nombrePadre":"Orgaz"},
	CM45E188: {"codigo":"CM45E188","nombre":"Villaluenga de la Sagra","nombrePadre":"Toledo"},
	CM45F189: {"codigo":"CM45F189","nombre":"Villamiel de Toledo","nombrePadre":"Torrijos"},
	CM45B190: {"codigo":"CM45B190","nombre":"Villaminaya","nombrePadre":"Orgaz"},
	CM45A191: {"codigo":"CM45A191","nombre":"Villamuelas","nombrePadre":"Oca\u00f1a"},
	CM45C192: {"codigo":"CM45C192","nombre":"Villanueva de Alcardete","nombrePadre":"Quintanar de la Orden"},
	CM45B193: {"codigo":"CM45B193","nombre":"Villanueva de Bogas","nombrePadre":"Orgaz"},
	CM45D194: {"codigo":"CM45D194","nombre":"Villarejo de Montalb\u00e1n","nombrePadre":"Talavera de la Reina"},
	CM45A195: {"codigo":"CM45A195","nombre":"Villarrubia de Santiago","nombrePadre":"Oca\u00f1a"},
	CM45E196: {"codigo":"CM45E196","nombre":"Villaseca de la Sagra","nombrePadre":"Toledo"},
	CM45A197: {"codigo":"CM45A197","nombre":"Villasequilla","nombrePadre":"Oca\u00f1a"},
	CM45A198: {"codigo":"CM45A198","nombre":"Villatobas","nombrePadre":"Oca\u00f1a"},
	CM45E199: {"codigo":"CM45E199","nombre":"Viso de San Juan, El","nombrePadre":"Toledo"},
	CM45B200: {"codigo":"CM45B200","nombre":"Y\u00e9benes, Los","nombrePadre":"Orgaz"},
	CM45E201: {"codigo":"CM45E201","nombre":"Yeles","nombrePadre":"Toledo"},
	CM45A202: {"codigo":"CM45A202","nombre":"Yepes","nombrePadre":"Oca\u00f1a"},
	CM45E203: {"codigo":"CM45E203","nombre":"Yuncler","nombrePadre":"Toledo"},
	CM45E204: {"codigo":"CM45E204","nombre":"Yunclillos","nombrePadre":"Toledo"},
	CM45E205: {"codigo":"CM45E205","nombre":"Yuncos","nombrePadre":"Toledo"}
}

const base = 'CM';
const largoFinal = 8;
const largos: number[] = [ 2, 4, 5, 8 ];
const divisiones: Record<string, DatosDivision> = {
	"2":{"id":1,"largo":2,"nombre":"comunidad aut\u00f3noma","plural":"comunidades aut\u00f3nomas","largoPadre":null,"largoHijos":4},"4":{"id":2,"largo":4,"nombre":"provincia","plural":"provincias","largoPadre":2,"largoHijos":5},"5":{"id":3,"largo":5,"nombre":"partido judicial","plural":"partidos judiciales","largoPadre":4,"largoHijos":8},"8":{"id":4,"largo":8,"nombre":"municipio","plural":"municipios","largoPadre":5,"largoHijos":null}	
};

const cyanRegions = {
	mapa,
	territorios,
	base,
	largoFinal,
	largos,
	divisiones
};

export { 
	mapa,
	territorios,
	base,
	largoFinal,
	largos,
	divisiones
 };
export default cyanRegions;

