
import { IonButton } from '@ionic/vue';
import { defineComponent } from "vue";
import store from "@/modules/adaptedStorage";
import { territorios } from '@/modules/cyanRegions';
import logout from '@/modules/cyanLogout';

export default defineComponent({
  name: "UserData",
  components: {
    IonButton
  },
  computed: {
    isLoggedIn() {
      return store.state.isLoggedIn;
    },
    userData() {
      return store.state.userData;
    },
    ambito() {
      if (this.userData.ambito) return (this as any).userData.ambito;
      const udn = (this as any).userData.nivel;
      return (udn in territorios) ? (territorios as any)[udn].nombre : '---'
    }
  },
  methods: {
    logout
  },
  props: {
    withLogout: {
      type: Boolean,
      default: false
    },
    bottom: {
      type: Boolean,
      default: false
    }
  }
});
