import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';

import store from '@/modules/adaptedStorage'
import cyanRequest from '@/modules/cyanRequest';

import cyanRegions, { territorios } from '@/modules/cyanRegions';

// Componentes de las vistas

const Home = () => import('../views/Home.vue')
const Login = () => import('../views/Login.vue')
const TwoFactorStart = () => import('../views/TwoFactorStart.vue')
const TwoFactorConfirm = () => import('../views/TwoFactorConfirm.vue')
const PartyLevels = () => import('../views/PartyLevels.vue')
const PickDepto = () => import('../views/PickDepto.vue')
const PickDeptoMpio = () => import('../views/PickDeptoMpio.vue')
const PickMpio = () => import('../views/PickMpio.vue')
const PartyList = () => import('../views/PartyList.vue')
const PartyMember = () => import('../views/PartyMember.vue')
const Voters = () => import('../views/Voters.vue')
const VoterList = () => import('../views/VoterList.vue')

const PuntoPickCentro = () => import('../views/PuntoPickCentro.vue')
const PuntoPickDepto = () => import('../views/PuntoPickDepto.vue')
const PuntoPickMpio = () => import('../views/PuntoPickMpio.vue')
const PuntoCyan = () => import('../views/PuntoCyan.vue')

const AgentePickDepto = () => import('../views/AgentePickDepto.vue')
const AgentePickMpio = () => import('../views/AgentePickMpio.vue')
const AgentePicker = () => import('../views/AgentePicker.vue')
const AgentePickCentro = () => import('../views/AgentePickCentro.vue')
const AgentePickJunta = () => import('../views/AgentePickJunta.vue')
const Agente = () => import('../views/Agente.vue') 

const DirPickDepto = () => import('../views/DirPickDepto.vue')
const DirPickMpio = () => import('../views/DirPickMpio.vue')
const DirPicker = () => import('../views/DirPicker.vue')
const DirPickCentro = () => import('../views/DirPickCentro.vue')
const DirPickJunta = () => import('../views/DirPickJunta.vue')
const Directorio = () => import('../views/Directorio.vue')
const DirectorioMember = () => import('../views/DirectorioMember.vue')

const PuntoCyanStreamlined = () => import('../views/PuntoCyanStreamlined.vue')
const IncidenciasStreamlined = () => import('../views/IncidenciasStreamlined.vue');
const DirectorioStreamlined = () => import('../views/DirectorioStreamlined.vue');
const DirectorioMemberStreamlined = () => import('../views/DirectorioMemberStreamlined.vue');
const CierreJrvStreamlined = () => import('../views/CierreJrvStreamlined.vue');
const ParticipacionJrvStreamlined = () => import('../views/ParticipacionJrvStreamlined.vue');
const ActasJrvStreamlined = () => import('../views/ActasJrvStreamlined.vue');
const ExitPollStreamlined = () => import('../views/ExitPollStreamlined.vue');

// Funciones de ayuda

// Almacenamiento persistente

const waitForStorageToBeReady = async (to: any, from: any, next: () => void) => {
  await (store as any).restored // Fix porque restored es un aumento de vuex-persistence
  next()
}

// Protección de las rutas

const checkLoggedIn = function(to: any, from: any, next: any) {

  const isGuest = to.matched.some( (record: RouteRecordRaw) => record.meta && record.meta.guest );

  if (!isGuest && !store.state.isLoggedIn) {
    next({
      path: '/login'
    })
    return
  } 

  if (isGuest && store.state.isLoggedIn) {
    next({
      path: '/home'
    })
    return
  }
  
  next()

}


// Filtro de niveles

function levelIsValid(x: string) { 

  // Chequeo 1: el nivel existe

  if (!(x in territorios)) return false;

  // Chequeo 2: el usuario está autenticado y su nivel es compatible con el solicitado

  if (!store.state.isLoggedIn) return false;

  const nivelUsuario = store.state.userData.nivel;

  let l = nivelUsuario.length;

  if (l > x.length) l = x.length;

  if (x.substr(0,l) !== nivelUsuario.substr(0,l)) return false;

  return true;

}

async function ensureHasSeedPartyData (seed: string, to: any, from: any, next: any) {
  if(typeof store.state.partyLists[seed] === 'undefined')
    store.commit('storePartyList', { codigo: seed, lista: { ok: false, reason: 'Cargando datos...' } });

  const currentData = store.state.partyLists[seed];

  const updaterPromise = cyanRequest('getMembers/' + seed, {
    isModal: !currentData.ok,
    silent: currentData.ok,
    needsAuth: true,
    timeout: 15000
  }).then(function(d: any) {
    if (d.ok) {
      store.commit('storePartyList', { codigo: seed, lista: d })
    }
  });

  if (!currentData.ok) 
    await updaterPromise;
  if (store.state.partyLists[seed] && store.state.partyLists[seed].ok)
    next();
}

const acjTouched: any = {};

async function ensureHasACJData (seed: string, to: any, from: any, next: any) {
  if(typeof store.state.allowedCentrosJuntas[seed] === 'undefined')
    store.commit('storeACJ', { nivel: seed, ok: false });

  const currentData = store.state.allowedCentrosJuntas[seed];

  const now = Date.now();

  const isCached = currentData.lastUpdate && now < currentData.lastUpdate + 15000;
  const isHammered = acjTouched[seed]     && now < acjTouched[seed] + 500;

  if (!isHammered && (!currentData.ok || !isCached)) {
    const updaterPromise = cyanRequest('allowedCentrosJuntas/' + seed, {
      isModal: !currentData.ok,
      silent: currentData.ok,
      needsAuth: true
    }).then(function(d: any) {
      if (d.ok) {
        store.commit('storeACJ', { ok: true, lastUpdate: now, ...d.allowedCentrosJuntas });
        acjTouched[seed] = now;
      }
    });
  
    if (!currentData.ok) {
      await updaterPromise;
    } 
  }

  acjTouched[seed] = now;

  if (store.state.allowedCentrosJuntas[seed] && store.state.allowedCentrosJuntas[seed].ok)
    next();
  else
    next({
      path: '/home'
    });
}

async function ensureHasMovilizadosData (centro: string, to: any, from: any, next: any) {
  if(typeof store.state.movilizados[centro] === 'undefined') {
    /* eslint-disable @typescript-eslint/camelcase */
    store.commit('storeMovilizados', { id_centro: centro, ok: false });
    /* eslint-enable @typescript-eslint/camelcase */
  }
  const currentData = store.state.movilizados[centro];

  const updaterPromise = cyanRequest('listaMovilizados/' + centro, {
    isModal: !currentData.ok,
    silent: currentData.ok,
    needsAuth: true
  }).then(function(d: any) {
    if (d.ok) {
      store.commit('storeMovilizados', d)
    }
  });

  if (!currentData.ok) 
    await updaterPromise;
  if (store.state.movilizados[centro] && store.state.movilizados[centro].ok)
    next();
}

async function ensureHasBootstrap (junta: string, to: any, from: any, next: any) {
  if(typeof store.state.bootstrap[junta] === 'undefined') {
    store.commit('storeBootstrap', { junta: {id: junta}, ok: false });
  }
  const currentData = store.state.bootstrap[junta];

  const updaterPromise = cyanRequest('agente/bootstrap/' + junta, {
    isModal: !currentData.ok,
    silent: currentData.ok,
    needsAuth: true
  }).then(function(d: any) {
    if (d.ok) {
      store.commit('storeBootstrap', d)
    }
  });

  if (!currentData.ok) 
    await updaterPromise;
  if (store.state.bootstrap[junta] && store.state.bootstrap[junta].ok)
    next();
}


async function ensureHasDirJunta (junta: string, to: any, from: any, next: any) {
  if(typeof store.state.dirJunta[junta] === 'undefined') {
    /* eslint-disable @typescript-eslint/camelcase */
    store.commit('storeDirJunta', { id_junta: junta, ok: false });
    /* eslint-enable @typescript-eslint/camelcase */
  }
  const currentData = store.state.dirJunta[junta];

  const updaterPromise = cyanRequest('directorioJunta/' + junta, {
    isModal: !currentData.ok,
    silent: currentData.ok,
    needsAuth: true,
    timeout: 15000
  }).then(function(d: any) {
    if (d.ok) {
      store.commit('storeDirJunta', d)
    }
  });

  if (!currentData.ok) 
    await updaterPromise;
  if (store.state.dirJunta[junta] && store.state.dirJunta[junta].ok)
    next();
}

async function ensureHasDirCentro (centro: string, to: any, from: any, next: any) {
  if(typeof store.state.dirCentro[centro] === 'undefined') {
    /* eslint-disable @typescript-eslint/camelcase */
    store.commit('storeDirCentro', { id_centro: centro, ok: false });
    /* eslint-enable @typescript-eslint/camelcase */

  }
  const currentData = store.state.dirCentro[centro];

  const updaterPromise = cyanRequest('directorioCentro/' + centro, {
    isModal: !currentData.ok,
    silent: currentData.ok,
    needsAuth: true,
    timeout: 15000
  }).then(function(d: any) {
    if (d.ok) {
      store.commit('storeDirCentro', d)
    }
  });

  if (!currentData.ok) 
    await updaterPromise;
  if (store.state.dirCentro[centro] && store.state.dirCentro[centro].ok)
    next();
}


async function ensureVotersWereLoaded (to: any, from: any, next: any) {

  const updaterPromise = cyanRequest('getVoters', {
    isModal: !store.state.votantesCargados,
    silent: store.state.votantesCargados,
    needsAuth: true
  }).then(function(d: any) {
    if (d.ok) {
      store.commit('storeVotantes', d.votantes)
    }
  });

  if (!store.state.votantesCargados) 
    await updaterPromise;

  if (store.state.votantesCargados)
    next();
}

function refreshUserData(to: any, from: any, next: any) {
  cyanRequest('userData', { 
    needsAuth: true,
    silent: true,
    isModal: false
  }).then(function(r) {
    if (r.ok) {
      store.commit('userUpdateData',r);
      return;
    } // Si !ok y hay algún error, cyanRequest se encarga de actualizarlo
  });

  next();
}

function checkStreamlinedPC(to: any, from: any, next: any) {
  if (store.state.isLoggedIn && store.getters.userHasPermission('pcsl'))
    next();
  else
    next({
      path: '/home'
    });
  return;
}

function checkStreamlinedJR(to: any, from: any, next: any) {
  if (store.state.isLoggedIn && store.getters.userHasPermission('jrsl'))
    next();
  else
    next({
      path: '/home'
    });
  return;
}

function checkStreamlinedBU(to: any, from: any, next: any) {

  if (!store.state.isLoggedIn || !store.getters.userHasPermission('busl'))
    next({
      path: '/home'
    });

    cyanRequest("busl/bocaUrna", {
      needsAuth: true,
      silent: true,
      retries: 3,
    }).then(function (d) {
      if (d && d.ok) {
        store.commit("patchUserData", d);
      }
  
    });
  
    next();
  

}

function directorioStreamlinedJR(to: any, from: any, next: any, soft?: boolean) {
  const datos = store.getters.directorioStreamlined;

  if (!store.state.isLoggedIn || !store.getters.userHasPermission('jrsl'))
    next({
      path: '/home'
    });

  if (!datos || !datos.ok) {
    store.commit("storeDirectorioStreamlined", false);
  } else {
    if (soft) return next();
  }
  cyanRequest("jrsl/directorio", {
    needsAuth: true,
    silent: true,
    retries: 3,
  }).then(function (d) {
    if (d && d.ok || !datos || !datos.ok) {
      store.commit("storeDirectorioStreamlined", d ?? false);
    }

  });

  next();

}


// Enrutado

const _g = { guest: true };

const routes: Array<RouteRecordRaw> = [
  { path: '/',            redirect: '/home',                                        },
  { path: '/home',        name: 'Home',         component: Home, 
                                                beforeEnter: refreshUserData        },
  { path: '/login',       name: 'Login',        component: Login,         meta: _g  },
  { path: '/twoFactorStart', name: 'TwoFactorStart', 
                                               component: TwoFactorStart, meta: _g  },
  { path: '/twoFactorConfirm', name: 'TwoFactorConfirm', 
                                             component: TwoFactorConfirm, meta: _g  },
  { path: '/partyLevels', name: 'PartyLevels',  component: PartyLevels,             },
  { path: '/pickDepto',   name: 'PickDepto',    component: PickDepto,               },
  { path: '/pickDeptoMpio', name: 'PickDeptoMpio',    component: PickDeptoMpio,     },
  { path: '/pickMpio/:seedPM',    
                          name: 'PickMpio',     component: PickMpio,
                          beforeEnter: (to, from, next) => {
                            if (to.params.seedPM.length != 4 || !levelIsValid(to.params.seedPM as string))
                              next('/home');
                            else 
                              next();
                          }                                                         },
  { path: '/partyList/:seedPL',    
                          name: 'PartyList',    component: PartyList,
                          beforeEnter: (to, from, next) => {
                            if (!levelIsValid(to.params.seedPL as string))
                              next('/home');
                            else {
                              ensureHasSeedPartyData(to.params.seedPL as string, to,from,next)
                            }
                          }                                                         },
  { path: '/partyMember/:seed/:idMember',    
                          name: 'PartyMember',    component: PartyMember,
                          beforeEnter: async (to, from, next) => {
                            if (!levelIsValid(to.params.seed as string))
                              next('/home');
                            else {
                              ensureHasSeedPartyData(to.params.seed as string, to,from,function() {

                                // Buscar el elemento con .id

                                const s = store.state.partyLists[to.params.seed as string];
                                const todosMiembros = [ ...s.organicos, ...s.coordinaciones ];
                                
                                for (const i of todosMiembros) {
                                  if (i.id == to.params.idMember) {
                                    to.params.memberData = i;
                                    return next();
                                  }
                                }

                                next('/home');

                              });

                            }
                          }                                                         },
  { path: '/voters',      name: 'Voters',          component: Voters,
                          beforeEnter: ensureVotersWereLoaded                       },
  { path: '/voterList',   name: 'VoterList',       component: VoterList,
                          beforeEnter: ensureVotersWereLoaded                       },


/////////////////////// RUTAS PUNTO CYAN /////////////////////////////


  { path: '/puntoPickDepto',   name: 'PuntoPickDepto',    component: PuntoPickDepto,               },
  { path: '/puntoPickMpio/:seedPM',    
                          name: 'PuntoPickMpio',     component: PuntoPickMpio,
                          beforeEnter: (to, from, next) => {
                            if (to.params.seedPM.length != 4 || !levelIsValid(to.params.seedPM as string))
                              next('/home');
                            else 
                              next();
                          }                                                         },
                        
  { path: '/puntoPickCentro/:seedPC',    
                          name: 'PuntoPickCentro',     component: PuntoPickCentro,
                          beforeEnter: (to, from, next) => {
                            if (to.params.seedPC.length != cyanRegions.largoFinal || !levelIsValid(to.params.seedPC as string))
                              next('/home');
                            else {
                              ensureHasACJData(to.params.seedPC as string,to,from,function(){
                                const acj = store.state.allowedCentrosJuntas[to.params.seedPC as string];

                                if (acj && acj.centros && acj.centrosFull.length == 1)
                                  next('/punto/' + to.params.seedPC + '/' + acj.centrosFull[0]);
                                else 
                                  next();
                              });
                            }
                          }                                                      
                        },

  { path: '/punto/:ambito/:centro',    
                        name: 'PuntoCyan',     component: PuntoCyan,
                        beforeEnter: (to, from, next) => {
                          const a = to.params.ambito as string;
                          if (a.length != cyanRegions.largoFinal || !levelIsValid(a)) {
                            return next('/home');
                          } else {
                            ensureHasACJData(a,to,from,function(){
                              const acj = store.state.allowedCentrosJuntas[a];
                              const c = parseInt(to.params.centro as string);
                              if (!acj.centrosFull.includes(c)) {
                                return next('/home');
                              }

                              ensureHasMovilizadosData(c+'', to, from, next);
                            });
                          }
                        }                                                      
                      },
                      
/////////////////////// RUTAS AGENTE /////////////////////////////

                      { path: '/agentePickDepto',   name: 'AgentePickDepto',    component: AgentePickDepto,               },
                      { path: '/agentePickMpio/:seedPM',    
                                              name: 'AgentePickMpio',     component: AgentePickMpio,
                                              beforeEnter: (to, from, next) => {
                                                if (to.params.seedPM.length != 4 || !levelIsValid(to.params.seedPM as string))
                                                  next('/home');
                                                else 
                                                  next();
                                              }                                                         },
                      { path: '/agentePicker/:seedPicker',    
                                              name: 'AgentePicker',     component: AgentePicker,
                                              beforeEnter: (to, from, next) => {
                                                if (to.params.seedPicker.length == cyanRegions.largoFinal || !levelIsValid(to.params.seedPicker as string))
                                                  next('/home');
                                                else 
                                                  next();
                                              }                                                         },
                                            
                      { path: '/agentePickCentro/:seedPC',    
                                              name: 'AgentePickCentro',     component: AgentePickCentro,
                                              beforeEnter: (to, from, next) => {
                                                if (to.params.seedPC.length != cyanRegions.largoFinal || !levelIsValid(to.params.seedPC as string))
                                                  next('/home');
                                                else {
                                                  ensureHasACJData(to.params.seedPC as string,to,from,function(){
                                                    const acj = store.state.allowedCentrosJuntas[to.params.seedPC as string];
                    
                                                    if (acj && acj.centros && acj.centros.length == 1)
                                                      next('/agentePickJunta/' + to.params.seedPC + '/' + acj.centros[0]);
                                                    else 
                                                      next();
                                                  });
                                                }
                                              }                                                      
                                            },

                      { path: '/agentePickJunta/:seedPJ/:centroPJ',
                                            name: 'AgentePickJunta',     component: AgentePickJunta,
                                            beforeEnter: (to, from, next) => {
                                              if (to.params.seedPJ.length != cyanRegions.largoFinal || !levelIsValid(to.params.seedPJ as string))
                                                next('/home');
                                              else {
                                                ensureHasACJData(to.params.seedPJ as string,to,from,function(){
                                                  const acj = store.state.allowedCentrosJuntas[to.params.seedPJ as string];
                                                  const centro = to.params.centroPJ as string;
                                                  if (acj.mapaCentrosJuntas && acj.mapaCentrosJuntas[centro]
                                                    && acj.mapaCentrosJuntas[centro].length == 1)
                                                    next('/agente/' + to.params.seedPJ + '/' + acj.mapaCentrosJuntas[centro][0]);
                                                  else 
                                                    next();
                                                });
                                              }
                                            }                                                      
                                          },
                  
                      { path: '/agente/:ambito/:junta',    
                                            name: 'Agente',     component: Agente,
                                            beforeEnter: (to, from, next) => {
                                              const a = to.params.ambito as string;
                                              if (a.length != cyanRegions.largoFinal || !levelIsValid(a)) {
                                                return next('/home');
                                              } else {
                                                ensureHasACJData(a,to,from,function(){
                                                  const acj = store.state.allowedCentrosJuntas[a];
                                                  const j = to.params.junta as string;
                                                  if (typeof acj.datosJuntas[j] === 'undefined') {
                                                    return next('/home');
                                                  }
                                                  ensureHasBootstrap(j, to, from, next);
                                                });
                                              }
                                            },
                                          },
                    

/////////////////////// RUTAS DIRECTORIO /////////////////////////////

{ path: '/dirPickDepto',   name: 'DirPickDepto',    component: DirPickDepto,               },
{ path: '/dirPickMpio/:seedPM',    
                        name: 'dirPickMpio',     component: DirPickMpio,
                        beforeEnter: (to, from, next) => {
                          if (to.params.seedPM.length != 4 || !levelIsValid(to.params.seedPM as string))
                            next('/home');
                          else 
                            next();
                        }                                                         },
{ path: '/dirPicker/:seedPicker',    
                        name: 'dirPicker',     component: DirPicker,
                        beforeEnter: (to, from, next) => {
                          if (to.params.seedPicker.length >= cyanRegions.largoFinal || !levelIsValid(to.params.seedPicker as string))
                            next('/home');
                          else 
                            next();
                        }                                                         },
                      
{ path: '/dirPickCentro/:seedPC',    
                        name: 'DirPickCentro',     component: DirPickCentro,
                        beforeEnter: (to, from, next) => {
                          if (to.params.seedPC.length != cyanRegions.largoFinal || !levelIsValid(to.params.seedPC as string))
                            next('/home');
                          else {
                            ensureHasACJData(to.params.seedPC as string,to,from,function(){
                              const acj = store.state.allowedCentrosJuntas[to.params.seedPC as string];

                              if (acj && acj.centros && acj.centros.length == 1) {
                                if (acj.centrosFull.length == 1) {
                                  next('/dirCentro/' + to.params.seedPC + '/' + acj.centros[0]);
                                } else {
                                  next('/dirPickJunta/' + to.params.seedPC + '/' + acj.centros[0]);
                                }
                              }
                              else 
                                next();
                            });
                          }
                        }                                                      
                      },

{ path: '/dirPickJunta/:seedPJ/:centroPJ',
                      name: 'DirPickJunta',     component: DirPickJunta,
                      beforeEnter: (to, from, next) => {
                        if (to.params.seedPJ.length != cyanRegions.largoFinal || !levelIsValid(to.params.seedPJ as string))
                          next('/home');
                        else {
                          ensureHasACJData(to.params.seedPJ as string,to,from,function(){
                            const acj = store.state.allowedCentrosJuntas[to.params.seedPJ as string];
                            const centro = to.params.centroPJ as string;
                            if (acj.mapaCentrosJuntas && acj.mapaCentrosJuntas[centro]
                              && acj.mapaCentrosJuntas[centro].length == 1)
                              next('/dirJunta/' + to.params.seedPJ + '/' + acj.mapaCentrosJuntas[centro][0]);
                            else 
                              next();
                          });
                        }
                      }                                                      
                    },

{ path: '/dirCentro/:ambito/:centro',    
                      name: 'DirCentro',     component: Directorio,
                      beforeEnter: (to, from, next) => {
                        const a = to.params.ambito as string;
                        if (a.length != cyanRegions.largoFinal || !levelIsValid(a)) {
                          return next('/home');
                        } else {
                          ensureHasACJData(a,to,from,function(){
                            const acj = store.state.allowedCentrosJuntas[a];
                            const c = to.params.centro as string;
                            if (typeof acj.datosCentros[c] === 'undefined') {
                              return next('/home');
                            }
                            ensureHasDirCentro(c, to, from, next);
                          });
                        }
                      }                                                      
                    },

  { path: '/dirCentroPC/:ambito/:centro',    
                    name: 'DirCentroPC',     component: Directorio,
                    beforeEnter: (to, from, next) => {
                      const a = to.params.ambito as string;
                      if (a.length != cyanRegions.largoFinal || !levelIsValid(a)) {
                        return next('/home');
                      } else {
                        ensureHasACJData(a,to,from,function(){
                          const acj = store.state.allowedCentrosJuntas[a];
                          const c = to.params.centro as string;
                          if (typeof acj.datosCentros[c] === 'undefined') {
                            return next('/home');
                          }
                          ensureHasDirCentro(c, to, from, next);
                        });
                      }
                    }                                                      
                  },

{ path: '/dirJunta/:ambito/:junta',    
                    name: 'DirJunta',     component: Directorio,
                    beforeEnter: (to, from, next) => {
                      const a = to.params.ambito as string;
                      if (a.length != cyanRegions.largoFinal || !levelIsValid(a)) {
                        return next('/home');
                      } else {
                        ensureHasACJData(a,to,from,function(){
                          const acj = store.state.allowedCentrosJuntas[a];
                          const j = to.params.junta as string;
                          if (typeof acj.datosJuntas[j] === 'undefined') {
                            return next('/home');
                          }
                          ensureHasDirJunta(j, to, from, next);
                        });
                      }
                    }                                                      
                  },
{ path: '/dirJuntaA/:ambito/:junta',    
                    name: 'DirJuntaA',     component: Directorio,
                    beforeEnter: (to, from, next) => {
                      const a = to.params.ambito as string;
                      if (a.length != cyanRegions.largoFinal || !levelIsValid(a)) {
                        return next('/home');
                      } else {
                        ensureHasACJData(a,to,from,function(){
                          const acj = store.state.allowedCentrosJuntas[a];
                          const j = to.params.junta as string;
                          if (typeof acj.datosJuntas[j] === 'undefined') {
                            return next('/home');
                          }
                          ensureHasDirJunta(j, to, from, next);
                        });
                      }
                    }                                                      
                  },

  { path: '/dirCentro/:ambito/:centro/:id',    
                  name: 'DirCentroMember',     component: DirectorioMember,
                  beforeEnter: (to, from, next) => {
                    const a = to.params.ambito as string;
                    if (a.length != cyanRegions.largoFinal || !levelIsValid(a)) {
                      return next('/home');
                    } else {
                      ensureHasACJData(a,to,from,function(){
                        const acj = store.state.allowedCentrosJuntas[a];
                        const c = to.params.centro as string;
                        if (typeof acj.datosCentros[c] === 'undefined') {
                          return next('/home');
                        }
                        ensureHasDirCentro(c, to, from, function() {
                          const dc = store.state.dirCentro[c];
                          if (!dc || !dc.datosCargos || !dc.datosCargos[to.params.id as string])
                            next('/home');
                          
                          next();
                        });
                      });
                    }
                  }                                                      
                },

{ path: '/dirCentroPC/:ambito/:centro/:id',    
                name: 'DirCentroMemberPC',     component: DirectorioMember,
                beforeEnter: (to, from, next) => {
                  const a = to.params.ambito as string;
                  if (a.length != cyanRegions.largoFinal || !levelIsValid(a)) {
                    return next('/home');
                  } else {
                    ensureHasACJData(a,to,from,function(){
                      const acj = store.state.allowedCentrosJuntas[a];
                      const c = to.params.centro as string;
                      if (typeof acj.datosCentros[c] === 'undefined') {
                        return next('/home');
                      }
                      ensureHasDirCentro(c, to, from, function() {
                        const dc = store.state.dirCentro[c];
                        if (!dc || !dc.datosCargos || !dc.datosCargos[to.params.id as string])
                          next('/home');
                        
                        next();
                      });
                    });
                  }
                }                                                      
              },

    { path: '/dirJunta/:ambito/:junta/:id',    
              name: 'DirJuntaMember',     component: DirectorioMember,
              beforeEnter: (to, from, next) => {
                const a = to.params.ambito as string;
                if (a.length != cyanRegions.largoFinal || !levelIsValid(a)) {
                  return next('/home');
                } else {
                  ensureHasACJData(a,to,from,function(){
                    const acj = store.state.allowedCentrosJuntas[a];
                    const j = to.params.junta as string;
                    if (typeof acj.datosJuntas[j] === 'undefined') {
                      return next('/home');
                    }
                    ensureHasDirJunta(j, to, from, function() {
                      const dj = store.state.dirJunta[j];
                      if (!dj || !dj.datosCargos || !dj.datosCargos[to.params.id as string])
                        next('/home');
                      
                      next();
                    });
                  });
                }
              }                                                      
            },
    { path: '/dirJuntaA/:ambito/:junta/:id',    
              name: 'DirJuntaMemberA',     component: DirectorioMember,
              beforeEnter: (to, from, next) => {
                const a = to.params.ambito as string;
                if (a.length != cyanRegions.largoFinal || !levelIsValid(a)) {
                  return next('/home');
                } else {
                  ensureHasACJData(a,to,from,function(){
                    const acj = store.state.allowedCentrosJuntas[a];
                    const j = to.params.junta as string;
                    if (typeof acj.datosJuntas[j] === 'undefined') {
                      return next('/home');
                    }
                    ensureHasDirJunta(j, to, from, function() {
                      const dj = store.state.dirJunta[j];
                      if (!dj || !dj.datosCargos || !dj.datosCargos[to.params.id as string])
                        next('/home');
                      
                      next();
                    });
                  });
                }
              }                                                      
            },


////////////////// RUTAS STREAMLINED //////////////////

{ path: '/pcslpart',   name: 'PuntoCyanStreamlined',    component: PuntoCyanStreamlined, beforeEnter: checkStreamlinedPC },
{ path: '/jrslinc',   name: 'IncidenciasStreamlined',    component: IncidenciasStreamlined, beforeEnter: checkStreamlinedJR },
{ path: '/jrsldir',   name: 'DirectorioStreamlined',    component: DirectorioStreamlined, beforeEnter: directorioStreamlinedJR },
{ path: '/jrslasist',   name: 'DirectorioStreamlinedAsistencia',    component: DirectorioStreamlined, beforeEnter: directorioStreamlinedJR },
{ path: '/jrsldir/:id',   name: 'DirectorioMemberStreamlined',    component: DirectorioMemberStreamlined, beforeEnter: function(a,b,c) { directorioStreamlinedJR(a,b,c,true); } },
{ path: '/jrslasist/:id',   name: 'DirectorioMemberStreamlinedAsistencia',    component: DirectorioMemberStreamlined, beforeEnter: function(a,b,c) { directorioStreamlinedJR(a,b,c,true); } },
{ path: '/jrslcierre',   name: 'CierreJrvStreamlined',    component: CierreJrvStreamlined, beforeEnter: directorioStreamlinedJR },
{ path: '/jrslpart',   name: 'ParticipacionJrvStreamlined',    component: ParticipacionJrvStreamlined, beforeEnter: directorioStreamlinedJR },
{ path: '/jrslactas',   name: 'ActaswJrvStreamlined',    component: ActasJrvStreamlined, beforeEnter: directorioStreamlinedJR },
{ path: '/buslexit',   name: 'ExitPollStreamlined',    component: ExitPollStreamlined, beforeEnter: checkStreamlinedBU },

////////////////// CATCH-ALL //////////////////

  { path: '/:pathMatch(.*)*',
                          redirect: '/home',                                        },
  
];

////////////////// CREACIÓN DE ROUTER CON FILTROS GENERALES //////////////////


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach(waitForStorageToBeReady);
router.beforeEach(checkLoggedIn);

export default router;
